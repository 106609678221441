import {PERIOD_SOURCE_TYPES} from "@/assets/enums"
import {AiWell, WellPeriod} from "@/core/types/ai-interfaces"

interface GetActualPeriodArg {
    period: WellPeriod
    well: AiWell
}
export function getActualPeriod({period, well}: GetActualPeriodArg) {
    if (!period || !well?.id) {
        return {}
    }
    const endDate = Number(!period.autoUpdate ? period.end_date : well.end_date || period.end_date)
    const periodKeys = Object.keys(period)
    return periodKeys.includes('start_date') && periodKeys.includes('start_depth') ?
        {
            start_date: period.start_date,
            start_depth: period.start_depth,
            end_date: Number(period.end_date) > endDate ? period.end_date : endDate,
            end_depth: !period.autoUpdate ? period.end_depth : well.end_depth || period.end_depth,
            source: period.source,
            ...period.source.params,
        } : {
            source: period.source,
        }
}

export function defaultPeriod(): WellPeriod {
    return {
        source: {
            type: PERIOD_SOURCE_TYPES.buttonWell,
        },
    }
}
