import {_apiCall as apiCall} from "@/utils/api"
import store from "@/store"

const getDefaultState = () => {
    return {}
}

const state = getDefaultState()

const getters = {}

const actions = {
    REQUEST_CATALOG_TOOLS: (ctx, data) => apiCall(ctx, {
        method: 'GET',
        url: `/bha/catalog${data?.filter ? '/' + data.filter : ''}`,
        params: {
            well_id: store.getters.getSelectedWellId
        }
    }),
}

const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
