import users from './users'
import user from './user'
import roles from './roles'
import userRoles from './user_roles'
import global from './global'
import company from './company'
import well from './well'
import references from './references'

export default {
    namespaced: true,
    modules: {
        users,
        user,
        userRoles,
        global,
        roles,
        company,
        well,
        references,
    }
}
