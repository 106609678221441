import Crud from '@/store/modules/crud.js'
import {toSelect} from "@/utils/helpers"

const URL = 'projects/${project_id}/groups'

const groups = new Crud(URL)

groups.getters = () => ({
    getGroups: state => state.data,
    getGroupsToSelect: state => toSelect(state.data),
})

export default groups.module()
