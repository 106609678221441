
    import {Component, Vue} from 'vue-property-decorator'
    import {SET_PORTRAIT_ORIENTATION} from '@/store/actions/params'
    import {THEMES, SKIN} from '@/assets/enums'
    import {Watch} from "vue-property-decorator/lib/decorators/Watch"

    @Component
    export default class App extends Vue {
        get userTheme() {
            return this.$store.getters.getUserTheme
        }
        get userSkin() {
            return this.$store.getters.getUserSkin
        }
        get landscapeOrientation() {
            return !this.$store.getters.isPortraitOrientation
        }

        get appClass() {
            let classArr = []
            if (this.userTheme === THEMES.BLACK) {
                classArr.push('theme--black')
            }
            if (this.userSkin === SKIN.BORDERED) {
                classArr.push('bordered')
            }
            if (this.landscapeOrientation) {
                classArr.push('landscape-orientation')
            }
            return classArr
        }

        private get isMobile() {
            return this.$vuetify.breakpoint.mobile
        }
        @Watch('isMobile', {immediate: true})
        @Watch('$route', {deep: true})
        mobileStateChanged() {
            if (this.isMobile && this.$route.matched.some(record => record.meta.hideInMobile)) {
                this.$router.push({name: '404'})
            }
        }

        mounted() {
            window.addEventListener(
                "orientationchange",
                this.handleOrientationChange
            )
            this.handleOrientationChange()
        }

        handleOrientationChange() {
            this.$store.commit(SET_PORTRAIT_ORIENTATION, window.orientation === 0)
        }
    }
