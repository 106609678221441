import Crud from '@/store/modules/crud.js'

const URL = 'projects/${project_id}/group_roles'

const groupRoles = new Crud(URL)

groupRoles.getters = () => ({
    getGroupRoles: state => state.data,
})

export default groupRoles.module()
