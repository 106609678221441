'use strict'

import Vue from 'vue'
import {apiCall, hasError} from '@/utils/api'
import {status} from '@/utils/helpers'
import {result} from "@/utils/helpers"

export default class Crud {
    constructor(urlTemplate, {params} = {}) {
        this.url = urlTemplate
        this.queryParams = params
    }

    getUrl(params = {}) {
        let compiled = _.template(this.url)
        return compiled(params)
    }

    addQueryParams(params = {}) {
        return {...params, ...result(this.queryParams)}
    }

    _apiCall(context, params, key) {
        return new Promise((resolve, reject) => {
            apiCall(params)
                .then(resp => {
                    if (!params.skipCommit) {
                        context.commit(key, resp.data)
                    }
                    resolve(resp)
                })
                .catch(err => {
                    hasError(context, err)
                    reject(err)
                })
        })
    }

    defaultState() {
        return {
            data: [],
            headers: [],
            status: '',
        }
    }

    state() {
        return this.defaultState()
    }

    getters() {
        return {
            getData: state => state.data,
            getHeaders: state => state.headers,
        }
    }

    actions() {
        return {
            SHOW: ({commit}, params = {}) => {
                commit('CHANGE_STATUS', {name: 'processing', message: 'Showing'})
                return this._apiCall({commit}, {url: this.getUrl(params) + '/' + params.id, skipCommit: params.skipCommit}, 'SHOWED')
            },
            INDEX: ({commit}, params = {}) => {
                commit('CHANGE_STATUS', {name: 'processing', message: 'Listing'})
                return this._apiCall({commit}, {
                    url: this.getUrl(params),
                    params: this.addQueryParams(params.params),
                    skipCommit: params.skipCommit,
                }, 'SUCCESS')
                    .catch(err => {
                        commit('RESET')
                        return Promise.reject(err)
                    })
            },
            STORE: ({commit}, params) => {
                commit('CHANGE_STATUS', {name: 'processing', message: 'Storing'})
                return this._apiCall({commit}, {
                    method: 'POST',
                    url: this.getUrl(params),
                    params: this.addQueryParams(params.params),
                    data: params.data,
                    skipCommit: params.skipCommit,
                }, params.commit || 'STORED')
            },
            DELETE: ({commit}, params) => {
                commit('CHANGE_STATUS', {name: 'processing', message: 'Deleting'})
                return this._apiCall({commit}, {
                    method: 'DELETE',
                    url: this.getUrl(params) + (params.id ? '/' + params.id : ''),
                    params: this.addQueryParams(params.params),
                    data: params.data,
                    skipCommit: params.skipCommit,
                }, 'DELETED')
            },
            DELETE_BATCH: ({commit}, params) => {
                commit('CHANGE_STATUS', {name: 'processing', message: 'Deleting'})
                return this._apiCall({commit}, {
                    method: 'DELETE',
                    url: this.getUrl(params) + '/batch',
                    params: this.addQueryParams(params.params),
                    data: params.data,
                    skipCommit: params.skipCommit,
                }, 'DELETED_BATCH')
            },
            UPDATE: ({commit}, params) => {
                commit('CHANGE_STATUS', {name: 'processing', message: 'Updating'})
                return this._apiCall({commit}, {
                    method: 'PATCH',
                    url: this.getUrl(params) + (params.id ? '/' + params.id : ''),
                    params: this.addQueryParams(params.params),
                    data: params.data,
                    skipCommit: params.skipCommit,
                }, 'UPDATED')
            },
        }
    }

    mutations() {
        return {
            SHOWED: state => {
                state.status = status({name: 'success', message: 'Showed'})
            },
            CHANGE_STATUS: (state, status) => {
                state.status = status
            },
            STORED: (state, {data, position}) => {
                if (position >= 0) {
                    state.data.splice(position, 0, data)
                } else {
                    state.data.push(data)
                }
                state.status = status({name: 'success', message: 'Stored'})
            },
            DELETED: (state, data) => {
                //TODO: remove after responses had to be changed (CLOUD-6584)
                const id = _.get(data.data, 'id', data.data)
                if (id) {
                    const index = state.data.findIndex(item => item.id === id)
                    if (index > -1) state.data.splice(index, 1)
                }
                state.status = status({name: 'success', message: 'Deleted'})
            },
            DELETED_BATCH: (state, data) => {
                for (const id of data.data) {
                    const index = state.data.findIndex(item => item.id === id)
                    if (index > -1) state.data.splice(index, 1)
                }

                state.status = status({name: 'success', message: 'Deleted'})
            },
            UPDATED: (state, data) => {
                const id = data.data.id
                const index = state.data.findIndex(item => item.id === id)
                if (index > -1) state.data.splice(index, 1, {...data.data})
                state.status = status({name: 'success', message: 'Updated'})
            },
            UPDATED_BATCH: (state, data) => {
                data.data.forEach(item => {
                    const id = item.id
                    const index = state.data.findIndex(one => one.id === id)
                    if (index > -1) {
                        state.data.splice(index, 1, item)
                    } else {
                        state.data.push(item)
                    }
                })
                state.status = status({name: 'success', message: 'Updated'})
            },
            PATCHED: (state, data) => {
                const id = data.data.id
                const index = state.data.findIndex(item => item.id === id)
                state.data.splice(index, 1, {...state.data[index], ...data.data})
                state.status = status({name: 'success', message: 'Patched'})
            },
            SUCCESS: (state, data = undefined) => {
                let message = ''
                if (data) {
                    if (data.data) {
                        Vue.set(state, 'data', data.data)
                        message = 'Data saved'
                    }
                    if (data.headers || data.table_headers) {
                        Vue.set(state, 'headers', data.headers || data.table_headers)
                    }
                }
                state.status = status({name: 'success', message: message})
            },
            ERROR: (state, err) => {
                Vue.set(state, 'status', status({name: 'error', message: err.message}))
            },
            RESET: (state) => {
                const defaultState = this.defaultState()
                Object.keys(state).forEach(key => {
                    Vue.set(state, key, defaultState[key])
                })
            }
        }

    }

    module() {
        return {
            namespaced: true,
            state: this.state(),
            getters: this.getters(),
            actions: this.actions(),
            mutations: this.mutations(),
        }
    }
}
