import store from "@/store"
import {SET_PARAMETER} from "@/store/actions/params"
import {v4 as uuid} from "uuid"
import {IRequestConfig} from "@/utils/api"

interface IRequest {
    axiosRequest: Promise<unknown>
    controller?: AbortController
    requestConfig?: IRequestConfig
}

export class RequestManager {
    public isPending: boolean = false
    protected readonly requestQueue = new Map<string, IRequest>()
    private appLevelManager?: RequestManager

    constructor(appLevelManager?: RequestManager) {
        this.appLevelManager = appLevelManager
    }

    public addRequest(request: IRequest): string {
        const requestId = request.requestConfig?.requestId || uuid()
        this.requestQueue.set(requestId, request)
        this.setPendingStatus()
        request.axiosRequest.finally(() => {
            this.requestQueue.delete(requestId)
            this.setPendingStatus()
        })
        return requestId
    }

    public cancelRequest(uuid: string): void {
        const request = this.requestQueue.get(uuid)
        if (!this.appLevelManager) throw new Error('This method should be overrided in child class, or other RequestManager have to be provided!')
        if (request) {
            this.appLevelManager.cancelRequest(uuid)
            this.requestQueue.delete(uuid)
            this.setPendingStatus()
        }
    }

    public cancelAll() {
        this.requestQueue.forEach((request, id) => {
            if (!request.requestConfig?.uninterrupted) {
                this.cancelRequest(id)
            }
        })
        this.setPendingStatus()
    }

    private setPendingStatus() {
        this.isPending = this.requestQueue.size > 0
    }
}

const addPendingRequest = (value: number = 1) => {
    store.commit(SET_PARAMETER, {
        key: 'pendingRequests',
        value: store.getters.pendingRequestsNumber + value,
    })
}

const removePendingRequest = (value: number = 1) => {
    store.commit(SET_PARAMETER, {
        key: 'pendingRequests',
        value: store.getters.pendingRequestsNumber - value,
    })
}
class SingletonRequestManager extends RequestManager {

    public addRequest(request: IRequest): string {
        addPendingRequest()
        request.axiosRequest.finally(() => removePendingRequest())
        return super.addRequest(request)
    }

    public cancelRequest(uuid: string) {
        const request = this.requestQueue.get(uuid)
        if (request) {
            request.controller?.abort()
        }
    }

    public cancelAll() {
        removePendingRequest(this.requestQueue.size)
        super.cancelAll()
    }
}

export default new SingletonRequestManager()
