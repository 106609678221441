import {_apiCall} from "@/utils/api"
import {baseURL} from "@/utils/axios"
import {getUrl} from "@/utils/helpers"

const state = {}

const getters = {}

const actions = {
    FILE_UPLOAD: ({commit}, {url, urlInfo, method, data, params, onUploadProgress, requestId}) => {
        const what = {
            method: method || 'POST',
            data,
            headers: {'Content-Type': 'multipart/form-data'},
            params,
            onUploadProgress,
            requestId,
        }
        if (url) {
            what.url = url
        } else {
            what.url = getUrl(urlInfo)
        }
        return _apiCall({commit}, what)
    },
    INDEX: ({commit}, urlInfo = {}) => {
        return _apiCall({commit}, {url: getUrl(urlInfo)})
    },
    SEARCH: ({commit}, urlInfo = {}) => {
        return _apiCall({commit}, {method: 'POST', url: getUrl(urlInfo), data: urlInfo.data})
    },
    STORE: ({commit}, urlInfo = {}) => {
        return _apiCall({commit}, {method: 'POST', url: getUrl(urlInfo), data: urlInfo.data})
    },
    UPDATE: ({commit}, urlInfo = {}) => {
        return _apiCall({commit}, {method: 'PATCH', url: getUrl(urlInfo), data: urlInfo.data})
    },
    DELETE: ({commit}, urlInfo = {}) => {
        return _apiCall({commit}, {method: 'DELETE', url: getUrl(urlInfo), needSuccessNotification: true})
    },
    DELETES: ({commit}, urlInfo = {}) => {
        return _apiCall({commit}, {method: 'DELETE', url: getUrl(urlInfo), data: urlInfo.data, needSuccessNotification: true})
    },
    MOVE: ({commit}, urlInfo = {}) => {
        return _apiCall({commit}, {method: 'POST', url: getUrl(urlInfo), data: urlInfo.data, needSuccessNotification: true})
    },
    DOWNLOAD_BY_LINK: ({commit}, urlInfo = {}) => {
        return new Promise((resolve, reject) => {
            _apiCall({commit}, {
                method: urlInfo.method || 'POST',
                url: getUrl(urlInfo),
                data: urlInfo.data,
            })
                .then(resp => {
                    const what = {
                        url: 'file_drive/${well_id}/download/${file_code}',
                        well_id: urlInfo.well_id,
                        file_code: resp.data,
                    }
                    window.location = baseURL + getUrl(what)
                    resolve(resp)
                })
                .catch(err => {
                    console.error('Download error:', err)
                    reject(err)
                })
        })
    },
}

const mutations = {
    ERROR: (state, err) => {
        console.error(err.message)
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
