import Crud from '@/store/modules/crud.js'
import {toSelect} from "@/utils/helpers"

const URL = 'admin/configuration/time_formats'

const timeFormats = new Crud(URL)

timeFormats.getters = () => ({
    getTimeFormatsToSelect: state => toSelect(state.data, item => { return {text: `${item.label} (${item.format})`, value: item.id} }),
})

export default timeFormats.module()
