import Crud from '@/store/modules/crud.js'

const URL = 'rigs'

const rigs = new Crud(URL)

rigs.getters = () => ({
    getRigs: state => state.data,
    getRigsByContractorId: state => contractorId => state.data.filter(item => item.contractor_id === contractorId),
})

export default rigs.module()
