import store from "@/store"
import {formatIfValid} from "@/utils/helpers"
import {numberFormat} from "highcharts"
import {rules} from "@/utils/rules"
import moment from "moment"

"use strict"

const maskRegex = /[0-9\-+#]/
const notMaskRegex = /[^\d\-+#]/g

function getIndex(mask) {
    return mask.search(maskRegex)
}

export function processMask(mask = "#.##") {
    const maskObj = {}
    const len = mask.length
    const start = getIndex(mask)
    maskObj.prefix = start > 0 ? mask.substring(0, start) : ""

    // Reverse string: not an ideal method if there are surrogate pairs
    const end = getIndex(mask.split("").reverse().join(""))
    const offset = len - end
    const substr = mask.substring(offset, offset + 1)
    // Add 1 to offset if mask has a trailing decimal/comma
    const indx = offset + ((substr === "." || (substr === ",")) ? 1 : 0)
    maskObj.suffix = end > 0 ? mask.substring(indx, len) : ""

    maskObj.mask = mask.substring(start, indx)
    maskObj.maskHasNegativeSign = maskObj.mask.charAt(0) === "-"
    maskObj.maskHasPositiveSign = maskObj.mask.charAt(0) === "+"

    // Search for group separator & decimal; anything not digit,
    // not +/- sign, and not #
    let result = maskObj.mask.match(notMaskRegex)
    // Treat the right most symbol as decimal
    maskObj.decimal = (result && result[result.length - 1]) || "."
    // Treat the left most symbol as group separator
    maskObj.separator = (result && result[1] && result[0]) || ","

    // Split the decimal for the format string if any
    result = maskObj.mask.split(maskObj.decimal)
    maskObj.integer = result[0]
    maskObj.fraction = result[1]
    return maskObj
}

function processValue(value, maskObj, options) {
    let isNegative = false
    const valObj = {
        value
    }
    if (value < 0) {
        isNegative = true
        // Process only abs(), and turn on flag.
        valObj.value = -valObj.value
    }

    valObj.sign = isNegative ? "-" : ""

    // Fix the decimal first, toFixed will auto fill trailing zero.
    valObj.value = Number(valObj.value).toFixed(maskObj.fraction && maskObj.fraction.length)
    // Convert number to string to trim off *all* trailing decimal zero(es)
    valObj.value = Number(valObj.value).toString()

    // Fill back any trailing zero according to format
    // look for last zero in format
    const posTrailZero = maskObj.fraction && maskObj.fraction.lastIndexOf("0")
    let [valInteger = "0", valFraction = ""] = valObj.value.split(".")
    if (!valFraction || (valFraction && valFraction.length <= posTrailZero)) {
        valFraction = posTrailZero < 0
            ? ""
            : (Number("0." + valFraction).toFixed(posTrailZero + 1)).replace("0.", "")
    }

    valObj.integer = valInteger
    valObj.fraction = valFraction
    addSeparators(valObj, maskObj)

    // Remove negative sign if result is zero
    if (valObj.result === "0" || valObj.result === "") {
        // Remove negative sign if result is zero
        isNegative = false
        valObj.sign = ""
        if (valObj.result === "" && options.emptySign) {
            valObj.result = options.emptySign
        }
    }

    if (!isNegative && maskObj.maskHasPositiveSign) {
        valObj.sign = "+"
    } else if (isNegative && maskObj.maskHasPositiveSign) {
        valObj.sign = "-"
    } else if (isNegative) {
        valObj.sign = options && options.enforceMaskSign && !maskObj.maskHasNegativeSign
            ? ""
            : "-"
    }

    return valObj
}

function addSeparators(valObj, maskObj) {
    valObj.result = ""
    // Look for separator
    const szSep = maskObj.integer.split(maskObj.separator)
    // Join back without separator for counting the pos of any leading 0
    const maskInteger = szSep.join("")

    const posLeadZero = maskInteger && maskInteger.indexOf("0")
    if (posLeadZero > -1) {
        while (valObj.integer.length < (maskInteger.length - posLeadZero)) {
            valObj.integer = "0" + valObj.integer
        }
    }

    // Process the first group separator from decimal (.) only, the rest ignore.
    // get the length of the last slice of split result.
    const posSeparator = (szSep[1] && szSep[szSep.length - 1].length)
    if (posSeparator) {
        const len = valObj.integer.length
        const offset = len % posSeparator
        for (let indx = 0; indx < len; indx++) {
            valObj.result += valObj.integer.charAt(indx)
            // -posSeparator so that won't trail separator on full length
            if (!((indx - offset + 1) % posSeparator) && indx < len - posSeparator) {
                valObj.result += maskObj.separator
            }
        }
    } else {
        valObj.result = valObj.integer
    }

    valObj.result += (maskObj.fraction && valObj.fraction)
        ? maskObj.decimal + valObj.fraction
        : ""
    return valObj
}

const defaultFormatOptions = {
    emptySign: '-',
    emptyValues: [null, undefined, 'NaN', Number.NaN, '-'],
    multiplier: 1,
    unit: '',
    keepLocalTime: false,
    isEmpty: v => {
        return !(v || v === 0)
    },
    enforceMaskSign: false,
}

function formatDate(date, fieldType, formatType, options = {}) {
    options = {
        ...defaultFormatOptions,
        ...options,
    }

    if (isNaN(parseFloat(date))) {
        // Invalid inputs
        return options.emptyValues.includes(date) ? options.emptySign : date
    }

    let format = ''
    switch (fieldType.toLowerCase()) {
        case 'date':
            format = store.getters.getLocaleDateFormat
            break
        case 'time':
            format = store.getters.getLocaleTimeFormat(formatType)
            break
        default: { //datetime by default
            formatType = formatType && formatType !== 'nonformatted' ? formatType : 'short'
            format = store.getters.getLocaleDateTimeFormat(formatType)
        }
    }
    return formatIfValid(date, {
        keepLocalTime: options.keepLocalTime,
        format,
    })?.toUpperCase() || options.emptySign
}

export default function format(value, fieldType, formatType, options = {}) {
    const validNumericTypes = [
        'date', 'time', 'datetime',
        'number', 'float',
        'int', 'integer',
    ]

    fieldType = fieldType ? fieldType.toLowerCase() : 'float' // default format type

    options = {
        ...defaultFormatOptions,
        ...options,
    }

    if (!validNumericTypes.includes(fieldType)) { // wrong fieldType
        if (options.emptyValues.includes(value)) {
            return options.emptySign
        }
        return value
    }

    if (isNaN(parseFloat(value))) { // wrong input value, only for NULL/UNDEFINED and NUMERIC
        if (options.emptyValues.includes(value)) {
            return options.emptySign
        }
        console.warn(`format: Invalid input value (${value}). Valid only NULL/UNDEFINED and NUMERIC`)
        return value
    }

    if (['date', 'time', 'datetime'].includes(fieldType) && typeof value === 'object' && value !== null) {
        value = moment(value).utc(false).valueOf()
    }

    const locale = store.getters.getLocale

    switch (fieldType) {
        case 'date':
            return formatDate(value, 'date', formatType, options)
        case 'time':
            return formatDate(value, 'time', formatType, options)
        case 'datetime': {
            return formatDate(value, 'datetime', formatType, options)
        }
        case 'number':
        case 'float': {
            formatType = formatType ? formatType : 'default'
            const mask = options.mask || _.get(locale.number_format, formatType)
            let v = parseFloat(value)
            return formatNumberByMask(v, mask, options)
        }
        case 'int':
        case 'integer': {
            const mask = '0'
            let v = parseInt(value)
            return formatNumberByMask(v, mask, options)
        }
        // case 'nonformatted':
        //     return value !== 'string' ? value.toString() : value
        default:
            return value
            // return value !== 'string' ? value.toString() : value
    }
}

export function formatNumberByMask(v, mask, options = {}) {
    options = {
        ...defaultFormatOptions,
        ...options,
    }
    if (options.isEmpty(v)) {
        return options.emptySign
    }
    v = v * options.multiplier
    v = Math.abs(v) < Number.EPSILON ? 0 : v
    const maskObj = processMask(mask)
    const valObj = processValue(v, maskObj, options)
    return maskObj.prefix + valObj.sign + valObj.result + maskObj.suffix + options.unit
}

export function getFractionLengthFromLocale(formatType = 'default') {
    const mask = _.get(store.getters.getLocale.number_format, formatType)
    return processMask(mask).fraction.length
}

export function formatNumber(value, formatType = 'default') {
    if (isNaN(value) || value === '') {
        return value
    }
    const fLength = getFractionLengthFromLocale(formatType)
    return rules.digitCapacity(100, getFractionLengthFromLocale(formatType), value) !== true ?
        Math.round(Math.pow(10, fLength) * value) / Math.pow(10, fLength) :
        value
}

export function renderDTValue(key, data) {
    let template = ''
    switch (data[`${key}_format`]) {
        case 'time':
            template = '<span class="time" title="${time}">${time}</span>'
            break
        case 'date':
            template = '<span class="date" title="${date}">${date}</span>'
            break
        default: // 'datetime'
            template = '<div title="${date}, ${time}"><div class="date">${date}</div><div class="time">${time}</div></div>'
    }
    const compiled = _.template(template)
    return compiled({
        date: format(data[key], 'date', 'short'),
        time: format(data[key], 'time', 'short'),
    })
}

export function chartsNumberFormat(number, decimals, decimalPoint, thousandsSep) {
    const _decimals = typeof decimals === 'number' ? decimals : 2
    const _decimalPoint = decimalPoint ? decimalPoint : '.'
    const _thousandsSep = thousandsSep ? thousandsSep : ''
    return numberFormat(number, _decimals, _decimalPoint, _thousandsSep)
}
