import wells from './wells'
import groups from './groups'

export default {
    namespaced: true,
    modules: {
        wells,
        groups,
    }
}
