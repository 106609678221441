import {Settable} from "@/utils/functions"

export interface FilterItem {
    id?: string,
    name?: string,
    showDialog?: boolean,
    icon?: string
}

export interface DateFilterItem extends FilterItem {
    from?: number
    to?: number
    last_period?: FilterItem
}


export const prepareDateFilter = (dateFilter?: DateFilterItem) => {
    if (!dateFilter || !dateFilter.id) return {}
    if (dateFilter.id === 'custom') return {from: dateFilter.from, to: dateFilter.to}
    return {last_period: dateFilter}
}

export interface IFiltersListCtorArgs {
    search_string?: Array<string>
    feature_string?: string
    operators?: Array<FilterItem>
    counties?: Array<FilterItem>
    states?: Array<FilterItem>
    well_basins?: Array<FilterItem>
    pads?: Array<FilterItem>
    fields?: Array<FilterItem>
    rig_contractors?: Array<FilterItem>
    rigs?: Array<FilterItem>
    well_statuses?: Array<FilterItem>
    wells?: Array<FilterItem>
    spud_date?: DateFilterItem
    release_date?: DateFilterItem
    excluded?: Array<FilterItem>
    is_personal?: Array<FilterItem>
    target_formations?: Array<FilterItem>
    hole_sizes?: Array<FilterItem>
    sorting_criteria?: string
    wellbore_types?: Array<FilterItem>
    isLock?: boolean
}
export default class FiltersList implements Settable {
    search_string: Array<string>
    feature_string: any
    operators: Array<FilterItem>
    counties: Array<FilterItem>
    states: Array<FilterItem>
    well_basins: Array<FilterItem>
    pads: Array<FilterItem>
    fields: Array<FilterItem>
    rig_contractors: Array<FilterItem>
    rigs: Array<FilterItem>
    well_statuses: Array<FilterItem>
    wells: Array<FilterItem>
    spud_date?: DateFilterItem
    release_date?: DateFilterItem
    excluded: Array<FilterItem>
    is_personal: Array<FilterItem>
    target_formations: Array<FilterItem>
    hole_sizes: Array<FilterItem>
    sorting_criteria: string
    wellbore_types: Array<FilterItem>
    isLock: boolean

    constructor(data: IFiltersListCtorArgs = {}) {
        this.search_string = data.search_string || []
        this.feature_string = data.feature_string || ''
        this.operators = data.operators || []
        this.counties = data.counties || []
        this.states = data.states || []
        this.well_basins = data.well_basins || []
        this.pads = data.pads || []
        this.fields = data.fields || []
        this.rig_contractors = data.rig_contractors || []
        this.rigs = data.rigs || []
        this.well_statuses = data.well_statuses || []
        this.wells = data.wells || []
        this.spud_date = data.spud_date
        this.release_date = data.release_date
        this.excluded = data.excluded ?? []
        this.is_personal = data.is_personal ?? []
        this.target_formations = data.target_formations ?? []
        this.hole_sizes = data.hole_sizes ?? []
        this.sorting_criteria = data.sorting_criteria || ''
        this.wellbore_types = data.wellbore_types ?? []
        this.isLock = data.isLock || false
    }

    setFilter<K extends keyof this>(key: K, value: this[K]) {
        this[key] = value
    }

    set<K extends keyof this>(filters: Record<K, any>) {
        for (const key in filters) {
            this.setFilter(key, filters[key])
        }
    }

    getDTO(): Record<string, any> {
        return {
            ...this,
            spud_date: prepareDateFilter(this.spud_date),
            release_date: prepareDateFilter(this.release_date),
        }
    }

    get isMapFilterEmpty() {
        if (this['feature_string']) {
            return false
        }
        return true
    }

    get isWellFiltersEmpty() {
        for (const key in this) {
            if (Array.isArray(this[key]) && (this[key] as unknown as Array<unknown>).length) {
                return false
            }
            if (typeof this[key] === 'object' && !_.isEmpty(this[key])) {
                return false
            }
        }

        return true
    }

    get isFiltersEmpty() {
        return this.isMapFilterEmpty && this.isWellFiltersEmpty
    }
}
