import wells from './wells'
import employees from './employees'

export default {
    namespaced: true,
    modules: {
        wells,
        employees,
    }
}
