export const CAPTION_FONT_SIZE = '11px'
export const FONT_SIZE = '12px'
export const CREDITS_FONT_SIZE = '10px'
export const GRID_COLOR_DARK = '#797979'
export const GRID_COLOR_LIGHT = '#CCCCCC'
export const BLUE = '#007AFF'
export const RED = '#ff0000'
export const WHITE = '#ffffff'
export const BLUE_OPACITY = 'rgba(0, 122, 255,.7)'
export const RED_OPACITY = 'rgba(255,0,0,.7)'
export const CORAL = '#FF3B30'
export const GREEN = '#42D552'
export const ORANGE = '#FF9500'
export const YELLOW = '#FCDC5C'
export const TURQUOISE = '#1E8888'
export const CRIMSON = '#F50055'
export const GREY = '#7F7F7F'
export const PURPLE = '#886CFF'
export const VIOLET = '#B054EC'
export const TRANSPARENT_GREY = 'rgba(127, 127, 127,.5)'
export const LIGHTGREY = '#BFBFBF'
export const LIGHTGREY_OPACITY = 'rgba(191, 191, 191,.5)'
export const DARKGREY_OPACITY = 'rgba(73, 73, 73, .8)'

export const FONT_GREEN = GREEN
export const FONT_RED = '#F44336'
export const FONT_BLUE = '#2196F3'
export const DASH = "\u2013"
export const GUNMETAL_GREY = '#818589'

export const WARNING_COLOR = '#FB8C00'
export const PROTOTYPES_COLOR = '#9CBBE9'
