import Crud from '@/store/modules/crud.js'
import {toSelect} from "@/utils/helpers"

const URL = 'references/countries'

const countries = new Crud(URL)

countries.getters = () => ({
    getCountriesToSelect: state => toSelect(state.data, 'name'),
})

export default countries.module()
