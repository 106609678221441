import wells from './wells'
import rigs from './rigs'
import frac_fleets from './frac_fleets'
import references from './references'
import companies from './companies'
import company from './company'
import well from './well'
import externalAccounts from './external_accounts'
import ssoAccounts from './sso_accounts'
import surveyProviders from './survey_providers'
import projects from './projects'
import project from './project'
import alert from './alert'
import report_management from './report_management'

export default {
    namespaced: true,
    modules: {
        references,
        companies,
        wells,
        rigs,
        frac_fleets,
        well,
        company,
        externalAccounts,
        ssoAccounts,
        surveyProviders,
        projects,
        project,
        alert,
        report_management,
    }
}
