import Crud from '@/store/modules/crud.js'
import {toSelect} from "@/utils/helpers"

const URL = 'projects'

const projects = new Crud(URL)

projects.getters = () => ({
    getProjects: state => state.data,
    getProjectsToSelect: state => toSelect(state.data),
})

export default projects.module()
