import Vue from 'vue'
import {Vuetify} from '@aidriller/ui-kit'
import brandColors from '@/assets/colors'
import '@mdi/font/css/materialdesignicons.css'
import '@aidriller/ui-kit/global-styles/ai-global.scss'
import {VuetifyDirectives} from '@aidriller/ui-kit'


Vue.use(Vuetify, {
    directives: {
        ClickOutside: VuetifyDirectives.ClickOutside,
    },
})

export default new Vuetify({
    breakpoint: {
        mobileBreakpoint: 'sm' // This is equivalent to a value of 960
    },
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            dark: {
                background: '#303030', // sync with $material-dark.background in /sass/variables.scss
                brand: brandColors.brand.base,
                default: brandColors.white,
                primary: '#3ea6ff',
                secondary: '#bdbdbd', // secondary: colors.grey.lighten1,
                disabled: '#bdbdbd', // disabled: colors.grey.lighten1,
            },
            light: {
                background: '#F5F5F7', // sync with $material-light.background in /sass/variables.scss
                brand: brandColors.brand.base,
                default: brandColors.black,
                primary: '#1976d2',
                disabled: '#757575', // disabled: colors.grey.darken1,
            }
        },
    }
})
