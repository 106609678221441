import {Nullable} from "@/types/common"
import ThrowableMap from "../ThrowableMap"

function generateKey(cachedFunction: Function, ...args: Array<any>) {
    return `${cachedFunction.name}.${JSON.stringify(args)}`
}

interface ICacheArgs {
    executor: CallableFunction
    expire?: Nullable<number>
}
const defaultOptions = () => ({
    expire: null, // date of data expiring
})
export default class RequestCacheManager {
    private static readonly listeners: ThrowableMap<string, {reject: Array<Function>, resolve: Array<Function>}> = new ThrowableMap()
    private static readonly localCache: ThrowableMap<string, {expire: Nullable<number>, payload: any}> = new ThrowableMap()

    static async cache<T = any>(parameters: CallableFunction | ICacheArgs, ...args: Array<any>) {
        const {executor, expire} = typeof parameters !== 'function' ? 
            {
                ...defaultOptions(),
                ...parameters,
            } :
            {
                ...defaultOptions(),
                executor: parameters, // cached function
            }
        return new Promise<T>((resolve, reject) => {
            const key = generateKey(executor, ...args)
            if (RequestCacheManager.localCache.has(key)) {
                const {expire, payload} = RequestCacheManager.localCache.get(key)
                if (!expire || expire > Date.now()) {
                    resolve(payload)
                    return
                }
                // invalidate expired data
                RequestCacheManager.localCache.delete(key)
            }

            if (!RequestCacheManager.listeners.has(key)) {
                RequestCacheManager.listeners.set(key, {resolve: [], reject: []})
                executor(...args)
                    .then((payload: T) => {
                        RequestCacheManager.localCache.set(key, {
                            payload, 
                            expire: expire ? Date.now() + expire : null
                        })
                        RequestCacheManager.listeners.get(key)?.resolve.forEach(fn => fn(payload))
                    })
                    .catch((err: unknown) => RequestCacheManager.listeners.get(key)?.reject.forEach(fn => fn(err)))
                    .finally(() => RequestCacheManager.listeners.delete(key))
            }
            RequestCacheManager.listeners.get(key).resolve.push(resolve)
            RequestCacheManager.listeners.get(key).reject.push(reject)
        })
    }

    static reset(cachedFunction: Function, ...args: Array<any>) {
        const key = generateKey(cachedFunction, ...args)
        RequestCacheManager.localCache.delete(key)
    }
}
