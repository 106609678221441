import {CRIMSON, ORANGE} from "@/assets/charts/themes"
import Enum from "@/utils/enum"

export const FILENAME_MAX_LENGTH = 150
//export const MAX_UPLOAD_FILE_SIZE = 2147483648 // in bytes: 2Gb = 2147483648 B
//export const MAX_UPLOAD_FILE_SIZE = 1610612736 // in bytes: 1.5Gb = 1610612736 B
export const MAX_UPLOAD_FILE_SIZE = 2048000000 // As used in back

export const SECONDS_IN_MINUTE = 60
export const MINUTES_IN_HOUR = 60

export const MS_IN_SECOND = 1000
export const MS_IN_MINUTE = SECONDS_IN_MINUTE * MS_IN_SECOND
export const MS_IN_HOUR = MINUTES_IN_HOUR * MS_IN_MINUTE
export const MS_IN_DAY = 24 * MS_IN_HOUR

export const DEFAULT_DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss"

export const ONLY_ME = 'ONLY_ME'
export const EVERYONE = 'EVERYONE'

// Projections methods
export const STRAIGHT_LINE = 'STRAIGHT_LINE'
export const LAST_DLS_TF = 'LAST_DLS_TF'
export const TREND_DLS_TF = 'TREND_DLS_TF'
export const MANUAL_CL_INC_AZM = 'MANUAL_CL_INC_AZM'
export const MANUAL_MD_INC_AZM = 'MANUAL_MD_INC_AZM'
export const MANUAL_CL_DLS_TF = 'MANUAL_CL_DLS_TF'
export const MANUAL_CL_DLS_MTF = 'MANUAL_CL_DLS_MTF'
export const SMART = 'SMART'
export const MANUAL_TVD_INC_AZM = 'MANUAL_TVD_INC_AZM'
export const MANUAL_TVD_DLS_TF = 'MANUAL_TVD_DLS_TF'
export const SMART_TO_CL = 'SMART_TO_CL'

// Quick plan Scenarios
export const TARGET_RETURN = 'TARGET_RETURN'
export const TARGET_CL = 'TARGET_CL'
export const MINIMUM_TIME = 'MINIMUM_TIME'
export const TARGET_DISTANCE = 'TARGET_DISTANCE'
export const MINIMUM_DISTANCE = 'MINIMUM_DISTANCE'
export const MINIMUM_SLIDE = 'MINIMUM_SLIDE'
export const MAX_INC_AZM = 'MAX_INC_AZM'
export const LIMIT_SLIDE = 'LIMIT_SLIDE'

export const BACK2PLAN_REF_PLAN  = 'plan'
export const BACK2PLAN_REF_TARGET_LINE  = 'target_line'

export const VERTICAL = 'VERTICAL'
export const HORIZONTAL = 'HORIZONTAL'
export const TIME = 'TIME'
export const DEPTH = 'DEPTH'
export const MD = 'MD'
export const TVD = 'TVD'

export const DEFAULT_USER_INPUT_DEBOUNCE = 300 // 300ms for user's keyboard input

export const DEPTH_MINIMAL_ZOOM = 1 // 5 ft
export const TIME_MINIMAL_ZOOM = 60000 // 1 min

export const TIME_ZOOM_THRESHOLD = 300000 // 5 min
export const DEPTH_ZOOM_THRESHOLD = 20 // 20 ft

export const MIN_QUERY_SIZE = 300 // min query size for logs
export const companyTypes = {
    OPERATOR: 1,
    DD_COMPANY: 2,
    RIG_CONTRACTOR: 3,
    FRAC_COMPANY: 5,
}

export const COMPANY_TYPES = new Enum([
    {id: 1, name: 'op', title: 'Operator'},
    {id: 2, name: 'dd', title: 'DD Company'},
    {id: 3, name: 'rc', title: 'Rig Contractor'},
    {id: 5, name: 'ff', title: 'Frac Company'},
])

export const WELL_STATUSES = new Enum([
    {id: 1, name: 'active', title: 'Active', color: '#4CAF50'},
    {id: 2, name: 'paused', title: 'Paused', color: CRIMSON},
    {id: 4, name: 'planned', title: 'Planned', color: ORANGE},
    {id: 3, name: 'completed', title: 'Completed', color: 'grey'},
])

export const COMPANY_STATUSES = new Enum([
    {id: 1, name: 'active', title: 'Active', color: '#4CAF50'},
    {id: 2, name: 'suspended', title: 'Suspended', color: 'grey'},
], {key: 'id'})

export const USER_STATUSES = new Enum([
    {id: 1, name: 'new', title: 'New', color: 'primary'},
    {id: 2, name: 'active', title: 'Active', color: 'success'},
    {id: 3, name: 'suspended', title: 'Suspended', color: 'grey'},
], {key: 'id'})

export const CONNECTION = new Enum([
    {id: 0, name: 'offline', title: 'Offline', color: 'error'},
    {id: 1, name: 'online', title: 'Online', color: 'success'},
])

export const POINT_TYPES = new Enum([
    {id: 0, name: 'depth', event: 'depthlog', url: 'depthpoint'},
    {id: 1, name: 'date', event: 'timelog', url: 'timepoint'},
])

export const TEMPLATE_EDIT_NOT_ALLOWED_MESSAGE = 'This is a default system template, please make a copy if you want to customize it.'

export const SURVEY_TYPE = new Enum([
    {id: 0, name: 'PLAN', label: 'Plan'},
    {id: 1, name: 'FACT', label: 'Survey'},
])

export const SEPARATION_REFERENCE_TYPE = new Enum([
    {id: 0,  name: 'PLAN', label: 'Def Plan'},
    {id: 1,  name: 'FACT', label: 'Survey'},
])

export const SURVEY_VARIANT_TYPE = new Enum([
    {id: 0, name: 'PRINCIPAL'},
    {id: 1, name: 'PROPOSAL'},
    {id: 3, name: 'GEOSTEERING'},
])

export const EDM_DATA_TYPE = new Enum([
    {order: 1, name: 'SURVEY_SOURCE', label: 'Survey Source', icon: 'mdi-alpha-s-box-outline', color: '#42d552', hideInFilter: true},
    {order: 2, name: 'FACT', label: 'Surveys', icon: 'mdi-alpha-s-box', color: '#42d552'},
    {order: 3, name: 'PLAN', label: 'Definitive Plans', icon: 'mdi-alpha-p-box', color: '#848894'},
    {order: 4, name: 'PROTOTYPE', label: 'Prototypes', icon: 'mdi-alpha-p-box-outline', color: '#848894'},
    {order: 5, name: 'AI_WELL_FACT_SURVEY', label: 'AI Surveys', icon: 'mdi-alpha-a-box', color: '#886CFF'},
])

export const EDM_TARGET_SHAPES = new Enum([
    {name: 'POINT', label: 'Point', icon: 'mdi-close mdi-rotate-45'},
    {name: 'CIRCLE', label: 'Circle', icon: 'mdi-circle-outline'},
    {name: 'ELLIPSE', label: 'Ellipse', icon: 'mdi-ellipse-outline'},
    {name: 'RECTANGLE', label: 'Rectangle', icon: 'mdi-square-outline'},
    {name: 'POLYGON', label: 'Polygon', icon: 'mdi-vector-union'},
])

export const EDM_BOUNDARY_TYPES = new Enum([
    {name: 'CONTOUR', label: 'Contour', icon: 'mdi-vector-square'},
    {name: 'LEASE_LINE', label: 'Leaseline', icon: 'mdi-vector-square'},
    {name: 'OTHER', label: 'Other', icon: 'mdi-vector-square'},
    {name: 'RIVER', label: 'River', icon: 'mdi-vector-square'},
    {name: 'ROAD', label: 'Road', icon: 'mdi-vector-square'},
])

export const FILE_DRIVE_CATEGORIES_TYPES = new Enum([
    {name: 'category_type', category_type: 'CATEGORY_TYPE'},
    {name: 'category', category_type: 'CATEGORY'},
    {name: 'folder', category_type: 'FOLDER'},
])

export const FILE_DRIVE_ICONS = {
    up: {
        icon: 'mdi-arrow-up',
        color: 'blue',
    },
    category: {
        icon: 'mdi-folder-outline',
        color: 'blue',
    },
    folder: {
        icon: 'mdi-folder-outline',
        color: 'blue',
    },
    xls: {
        icon: 'mdi-file-excel-outline',
        color: 'green',
    },
    xlsx: {
        icon: 'mdi-file-excel-outline',
        color: 'green',
    },
    csv: {
        icon: 'mdi-file-table-outline',
        color: 'grey',
    },
    xml: {
        icon: 'mdi-file-table-outline',
        color: 'grey',
    },
    doc: {
        icon: 'mdi-file-word-outline',
        color: 'blue',
    },
    docx: {
        icon: 'mdi-file-word-outline',
        color: 'blue',
    },
    pdf: {
        icon: 'mdi-file-pdf-box',
        color: 'red',
    },
    txt: {
        icon: 'mdi-note-text-outline',
        color: 'gray',
    },
    las: {
        icon: 'mdi-note-text-outline',
        color: 'gray',
    },
    jpg: {
        icon: 'mdi-file-image-outline',
        color: 'warning',
    },
    jpeg: {
        icon: 'mdi-file-image-outline',
        color: 'warning',
    },
    png: {
        icon: 'mdi-file-image-outline',
        color: 'warning',
    },
    zip: {
        icon: 'mdi-zip-box-outline',
        color: 'darkgrey',
    },
    '7z': {
        icon: 'mdi-zip-box-outline',
        color: 'darkgrey',
    },
}

export const DASH_STYLES = [
    {
        value: null,
        view: 'Default',
    },
    {
        value: 'Solid',
        view: '<svg width="100" height="10"><path fill="none" d="M 0 4 L 100 4" stroke="black" stroke-width="2" stroke-dasharray="none"></path></svg>',
    },
    {
        value: 'ShortDash',
        view: '<svg width="100" height="10"><path fill="none" d="M 0 4 L 100 4" stroke="black" stroke-width="2" stroke-dasharray="6,2"></path></svg>',
    },
    {
        value: 'ShortDot',
        view: '<svg width="100" height="10"><path fill="none" d="M 0 4 L 100 4" stroke="black" stroke-width="2" stroke-dasharray="2,2"></path></svg>',
    },
    {
        value: 'ShortDashDot',
        view: '<svg width="100" height="10"><path fill="none" d="M 0 4 L 100 4" stroke="black" stroke-width="2" stroke-dasharray="6,2,2,2"></path></svg>',
    },
    {
        value: 'ShortDashDotDot',
        view: '<svg width="100" height="10"><path fill="none" d="M 0 4 L 100 4" stroke="black" stroke-width="2" stroke-dasharray="6,2,2,2,2,2"></path></svg>',
    },
    {
        value: 'Dot',
        view: '<svg width="100" height="10"><path fill="none" d="M 0 4 L 100 4" stroke="black" stroke-width="2" stroke-dasharray="2,6"></path></svg>',
    },
    {
        value: 'Dash',
        view: '<svg width="100" height="10"><path fill="none" d="M 0 4 L 100 4" stroke="black" stroke-width="2" stroke-dasharray="8,6"></path></svg>',
    },
    {
        value: 'LongDash',
        view: '<svg width="100" height="10"><path fill="none" d="M 0 4 L 100 4" stroke="black" stroke-width="2" stroke-dasharray="16,6"></path></svg>',
    },
    {
        value: 'DashDot',
        view: '<svg width="100" height="10"><path fill="none" d="M 0 4 L 100 4" stroke="black" stroke-width="2" stroke-dasharray="8,6,2,6"></path></svg>',
    },
    {
        value: 'LongDashDot',
        view: '<svg width="100" height="10"><path fill="none" d="M 0 4 L 100 4" stroke="black" stroke-width="2" stroke-dasharray="16,6,2,6"></path></svg>',
    },
    {
        value: 'LongDashDotDot',
        view: '<svg width="100" height="10"><path fill="none" d="M 0 4 L 100 4" stroke="black" stroke-width="2" stroke-dasharray="16,6,2,6,2,6"></path></svg>',
    }
]

export const THICKNESS = [
    {
        value: null,
        label: 'Default',
    },
    {
        value: 1,
        label: '1px'
    },
    {
        value: 2,
        label: '2px'
    },
    {
        value: 3,
        label: '3px'
    },
    {
        value: 4,
        label: '4px'
    },
    {
        value: 5,
        label: '5px'
    }
]

export const LINE_SERIES_TYPES = [
    {
        value: 'LINE',
        label: 'Line',
    },
    {
        value: 'LEFT_STEPPED_LINE',
        label: 'Stepped Left',
    },
    {
        value: 'RIGHT_STEPPED_LINE',
        label: 'Stepped Right',
    },
]

export const CHART_TYPE = new Enum([
    {name: 'LINE', options: {type: 'line'}},
    {name: 'LEFT_STEPPED_LINE', options: {type: 'line', step: 'left'}},
    {name: 'RIGHT_STEPPED_LINE', options: {type: 'line', step: 'right'}},
])


export const GAUGE_HEIGHT = new Enum([
    {name: 'MOBILE_WITH_ROADMAP', value: 80},
    {name: 'MOBILE_WITHOUT_ROADMAP', value: 63},
    {name: 'DESKTOP_WITH_ROADMAP', value: 93},
    {name: 'DESKTOP_WITHOUT_ROADMAP', value: 64},
], {key: 'name', defaultReturnProperty: 'value'})

export const FILTER_DATA_PROPS = new Enum([
    {name: 'drilling_phase', icon: 'mdi-target-variant', color: 'warning'},
    {name: 'holesize', icon: 'mdi-radius-outline', color: 'warning'},
    {name: 'section', icon: 'mdi-spirit-level mdi-rotate-90', color: 'warning'},
    {name: 'depth', icon: 'mdi-ruler', color: 'warning'},
    {name: 'drilling_period', icon: 'mdi-clock-time-nine-outline', color: 'warning'},
], {key: 'name'})

export const ACTIVITY_FILTER_LIST = [
    {
        label: 'Rotary',
        id: 'ROTATION',
        icon: 'mdi-axis-z-rotate-clockwise'
    },
    {
        label: 'Slide',
        id: 'FULL_SLIDE',
        icon: 'mdi-arrow-oscillating'
    },
]
export const __reactiveInject__ = '__reactiveInject__'
