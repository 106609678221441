import Crud from '@/store/modules/crud.js'
import {toSelect} from "@/utils/helpers"
import {COMPANY_TYPES} from "@/assets/constants.js"

const URL = 'companies'

const companies = new Crud(URL)

companies.getters = () => ({
    getCompanies: state => state.data,
    getCompaniesToSelect: state => toSelect(state.data),
    getOPtoSelect: state => toSelect(state.data.filter(row => row.type_id === COMPANY_TYPES['op'].id)),
    getDDtoSelect: state => toSelect(state.data.filter(row => row.type_id === COMPANY_TYPES['dd'].id)),
    getRCtoSelect: state => toSelect(state.data.filter(row => row.type_id === COMPANY_TYPES['rc'].id)),
    getFFtoSelect: state => toSelect(state.data.filter(row => row.type_id === COMPANY_TYPES['ff'].id)),
})

export default companies.module()
