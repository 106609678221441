import Vue from 'vue'
import {DELETE_WIDGET} from "@/store/actions/widgets"
import {apiCall} from "@/utils/api"
import store from "@/store"
import WidgetFactory from "@/utils/WidgetFactory/index.ts"

const rootKeys = [
    'children',
    'class',
    'fragment_id',
    'id',
    'label',
    'parent_id',
    'public',
    // 'well_id',
    'owner_id',
]

const getDefaultWidget = item => {
    const config = JSON.parse(item.configuration)
    const pw_config = JSON.parse(item.per_well_config)
    //TODO: remove crunch
    if (config) {
        delete config.dimensionsTree
        if (config.statistics) {
            config.statistics.forEach(statistic => delete statistic.dimensionsTree)
        }
    }
    if (pw_config) {
        delete pw_config.dimensionsTree
        if (pw_config.statistics) {
            pw_config.statistics.forEach(statistic => delete statistic.dimensionsTree)
        }
    }
    const configuration = _.isEmpty(config) ? null : {...config, ..._.pick(item, rootKeys)}
    const per_well_config = _.isEmpty(pw_config) ? null : pw_config
    return {
        id: item.fragment_id,
        params: {
            ...item,
            configuration,
            per_well_config,
        },
    }
}

const getDefaultState = () => {
    return {
        data: [],
        perWellSettings: {},
    }
}

const state = getDefaultState()

const filterWidgets = (state, params) => {
    return _.filter(state.data, {params})
}

const extractChildren = fragments => {
    return fragments.map(item => {
        return [item, ...extractChildren(item.children || [])]
        // return [item, ...(item.children || [])]
    }).flat()
}

const getters = {
    getWidgets: state => state.data,
    getWidgetById: state => id => {
        const removeKeys = [
            'per_well_config',
        ]
        const widget = state.data.find(item => item.id === id)
        if (!widget) return
        return {
            id: widget.id,
            params: {
                ..._.omit(widget.params, removeKeys),
                configuration: _.merge({},  // merge config & per well config
                    widget.params.configuration,
                    widget.params.per_well_config,
                )
            }
        }
    },
    filterWidgets: state => params => {
        return _.filter(state.data, {params})
    },

    getWidgetByClass: () => clazz => {
        return store.getters['widgets/filterWidgets']({class: clazz})
    },
}

const actions = {
    DELETE_WIDGET: ({commit}, id) => {
        return new Promise(resolve => {
            const request = {
                method: 'DELETE',
                url: `backstore/fragment`,
                params: {id},
            }
            apiCall(request).then((resp) => {
                if (resp.status === 200) {
                    commit(DELETE_WIDGET, id)
                }
                resolve()
            })
        })
    },
    SAVE_WIDGET: async ({commit}, payload) => {
        const method = payload.id ? 'PATCH' : 'POST'
        const request = {
            method,
            url: `backstore/fragment`,
            data: {
                ..._.pick(payload, rootKeys),
                well_id: payload.wellId,
                label: payload.configuration?.name,
                configuration: _.isEmpty(payload.configuration) ? null : JSON.stringify(payload.configuration),
                per_well_config: _.isEmpty(payload.per_well_config) ? null : JSON.stringify(payload.per_well_config),
            },
        }
        const resp = await apiCall(request)
        commit('UPDATE_WIDGET', resp.data)
        return Promise.resolve(resp)
    },
    LOAD_WIDGETS_LIST: ({commit}, payload) => {
        const request = {
            method: 'GET',
            url: `backstore/fragment`,
            params: {
                ...payload,
                well_context: store.getters.getSelectedWellId,
            },
        }
        return apiCall(request)
            .then(resp => {
                commit('UPDATE_WIDGETS', extractChildren(resp.data))
                return Promise.resolve(filterWidgets(state, payload))
            })
    },
    LOAD_WIDGET: async ({commit}, {fragmentId, wellId}) => {
        if (!fragmentId) {
            throw new Error('empty fragment id!')
        }
        const widget = store.getters['widgets/getWidgetById'](fragmentId)
        if (widget) {
            const modelClass = await WidgetFactory.getModelClass(widget.params.class)
            if (widget.params.well_id === wellId || modelClass.perWellSettings.length === 0) {
                // if wellId id is same as when widget was requested or no perWell settings exist
                return Promise.resolve(widget)
            }
        }
        const request = {
            method: 'GET',
            url: `backstore/fragment`,
            params: {
                fragment_id: fragmentId,
                well_context: wellId,
            },
            uninterrupted: true,
        }
        return apiCall(request)
            .then(async resp => {
                const data = extractChildren(resp.data)
                commit('UPDATE_WIDGETS', data)
                return Promise.resolve(store.getters['widgets/getWidgetById'](fragmentId))
            })
    },
}

const mutations = {
    UPDATE_WIDGET: (state, item) => {
        const widget = getDefaultWidget(item)
        const index = state.data.findIndex(item => widget.id === item.id)
        if (index > -1) {
            Vue.set(state.data, index, widget)
        } else {
            Vue.set(state, 'data', [...state.data, widget])
        }
    },
    UPDATE_WIDGETS: (state, items) => {
        const widgets = [...state.data]
        items.forEach(item => {
            const widget = getDefaultWidget(item)
            const index = state.data.findIndex(item => widget.id === item.id)
            if (index > -1) {
                widgets[index] = widget
            } else {
                widgets.push(widget)
            }
        })
        Vue.set(state, 'data', widgets)
    },
    [DELETE_WIDGET]: (state, id) => {
        const widgets = state.data.filter(item => item.params.id !== id)
        Vue.set(state, 'data', widgets)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
