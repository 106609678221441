import Crud from '@/store/modules/crud.js'
import {toSelect} from "@/utils/helpers"

const URL = 'references/sso_providers'

const ssoProviders = new Crud(URL)

ssoProviders.getters = () => ({
    getSsoProvidersToSelect: state => toSelect(state.data, 'name'),
})

export default ssoProviders.module()
