import Vue from 'vue'
import moment from "moment"
import store from "@/store"
import {SYNC_FILTER} from "@/store/actions/params"
import {simpleErrorNotification} from "@/utils/helpers"
import {SYNC_TYPE} from "@/assets/enums"


const saveToStorage = (state) => {
    sessionStorage.setItem('history', JSON.stringify(state))
    localStorage.setItem('history', JSON.stringify(state))
}

const getStorage = () => {
    return JSON.parse(sessionStorage.getItem('history')) || JSON.parse(localStorage.getItem('history')) || {}
}

const state = getStorage()

const getters = {
    getHistoryWell: state => wellId => {
        return state[wellId] || historyItem()
    },
}

const historyItem = () => ({
    period: {},
})

const mutations = {
    SET_HISTORY: (state, data) => {
        if (!state[data.wellId]) {
            Vue.set(state, data.wellId, historyItem())
        }
        const newItem = {...historyItem(), ...data.value}
        Vue.set(state[data.wellId], data.key, newItem)

        saveToStorage(state)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
}

const getHistoryFromStorage = async (wellId) => {
    const history = getStorage()
    if (!history[wellId]) {
        return
    }
    const wellHistory = {...historyItem(), ...history[wellId]}
    let period = {
        end_date: moment(moment(wellHistory.period.end_date).isValid() ? wellHistory.period.end_date : undefined).utc(false).valueOf(),
        start_date: moment(moment(wellHistory.period.start_date).isValid() ? wellHistory.period.start_date : undefined).utc(false).valueOf(),
    }
    try {
        const resp = await store.dispatch(SYNC_FILTER, {
            start_date: period.start_date,
            end_date: period.end_date,
            sync_type: SYNC_TYPE.BY_DATE,
            well_id: wellId,
        })
        period.start_depth = _.get(resp, 'data.start_depth')
        period.end_depth = _.get(resp, 'data.end_depth')
        period.start_date = _.get(resp, 'data.start_date')
        period.end_date = _.get(resp, 'data.end_date')
    } catch (e) {
        simpleErrorNotification(e.data.message)
    }
    wellHistory.period = {
        ...wellHistory.period,
        ...period,
    }
    return wellHistory
}

export {getHistoryFromStorage}
