import Crud from '@/store/modules/crud.js'

const URL = 'projects/${project_id}/user_roles'

const userRoles = new Crud(URL)

userRoles.getters = () => ({
    getUserRoles: state => state.data,
})

export default userRoles.module()
