import {apiCall, hasError} from "@/utils/api"
import {WELL_ASSIGNED_USERS} from "@/store/actions/admin/user_roles"

const URL = 'admin/user_roles'

const state = {
    wellAssignedUsers: [],
}

const getters = {
    getWellAssignedUsers: state => state.wellAssignedUsers,
}

const actions = {
    DELETE: ({commit, dispatch}, id) => {
        return new Promise((resolve, reject) => {
            apiCall({
                method: 'DELETE',
                url: `${URL}/${id}`,
            })
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    hasError({commit, dispatch}, err)
                    reject(err)
                })
        })
    },
    UPDATE: ({commit, dispatch}, payload) => {
        return new Promise((resolve, reject) => {
            apiCall({
                method: 'POST',
                url: `${URL}/${payload.user_id}/${payload.object_id}/${payload.object_type}`,
                data: {role_id: payload.role_id}
            })
                .then(resp => {
                    commit(WELL_ASSIGNED_USERS, resp.data)
                    resolve(resp)
                })
                .catch(err => {
                    hasError({commit, dispatch}, err)
                    reject(err)
                })
        })
    },
    WELL_ASSIGNED_USERS: ({commit, dispatch}, well_id) => {
        const objType = 'WELL'
        return new Promise((resolve, reject) => {
            apiCall({method: 'GET', url: `${URL}/${well_id}/${objType}`})
                .then(resp => {
                    commit('WELL_ASSIGNED_USERS', resp.data)
                    resolve(resp)
                })
                .catch(err => {
                    hasError({commit, dispatch}, err)
                    reject(err)
                })
        })
    },
}

const mutations = {
    WELL_ASSIGNED_USERS: (state, data) => {
        state.wellAssignedUsers = data.roles
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
