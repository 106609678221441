import Crud from '@/store/modules/crud.js'

const URL = 'admin/sso_provider'

const ssoAccounts = new Crud(URL)

ssoAccounts.getters = () => ({
    getSsoAccounts: state => state.data,
})

const actions = ssoAccounts.actions()
ssoAccounts.actions = () => ({
    ...actions,
    UPDATE: ({commit}, params) => {
        commit('CHANGE_STATUS', {name: 'processing', message: 'Updating'})
        return ssoAccounts._apiCall({commit}, {
            method: 'PUT',
            url: ssoAccounts.getUrl(params) + (params.id ? '/' + params.id : ''),
            params: ssoAccounts.addQueryParams(params.params),
            data: params.data,
            skipCommit: params.skipCommit,
        }, 'UPDATED')
    }
})

export default ssoAccounts.module()
