/* eslint-disable no-unused-vars */

export enum AiControlType {
    // eslint-disable-next-line no-unused-vars
    NONE = 'no_type',
    OTHER = 'other',
    BOOLEAN = 'boolean',
    SWITCH = 'switch',
    NUMBER = 'number',
    FLOAT = 'float',
    FRACTION_NUMBER = 'fraction_number',
    INT = 'int',
    STRING = 'string',
    WRAPPED_STRING = 'wrapped_string',
    STRING_WITH_ICON = 'string_with_icon',
    RADIO = 'radio',
    SELECT = 'select',
    MULTISELECT = 'multiselect',
    AUTOCOMPLETE = 'autocomplete',
    COLOR = 'color',
    MENU = 'menu',
    DATE = 'date',
    TIME = 'time',
    TIMESTAMP = 'timestamp',
    OBJECT = 'object',
    FICTION_OBJECT = 'fiction_object',
    OBJECTS_LIST = 'objects_list',
    DIRECTORY = 'directory',
    TABLE = 'table',
    ICON = 'icon',
    MW_FILTER = 'mw_filter',
    MATRIX = 'matrix',
    OBJECT_LINE = 'object_line',
    MAIN = 'main',
    YES_NO = 'yes_no',
    AC_WARNING_CHIP_CELL = 'AcWarningChipCell',
    OFFSET_NAME_CELL = 'OffsetNameCell',
}

export enum AiDrawerPropType {
    TABS = 'tabs',
    TAB = 'tab',
    BUTTON = 'button',
    BUTTON_BIG = 'buttonBig',
    DATA_GROUP = 'dataGroup',
    BUTTON_GROUPS = 'buttonGroups',
    SWITCH = 'switch',
    SELECT = 'select',
    AUTOCOMPLETE = 'autocomplete',
    TEXT_FIELD = 'text',
    COLOR = 'color',
    COLOR_BTN = 'colorBtn',
    DEFAULT_VALUE = 'defaultValue',
    MENU = 'menu',
    LABEL = 'label',
    DIVIDER = 'divider',
    COMPONENT = 'component',
    NUMBER = 'number',
    SLIDER = 'slider',
    RANGE_SLIDER = 'rangeSlider',
    GROUP = 'group',
}

// export abstract class AiControlType {
//     static readonly NONE = Types.NONE
//     static readonly OTHER = Types.OTHER
//     static readonly BOOLEAN = Types.BOOLEAN
//     static readonly NUMBER = Types.NUMBER
//     static readonly FRACTION_NUMBER = Types.FRACTION_NUMBER
//     static readonly INT = Types.INT
//     static readonly STRING = Types.STRING
//     static readonly RADIO = Types.RADIO
//     static readonly SELECT = Types.SELECT
//     static readonly AUTOCOMPLETE = Types.AUTOCOMPLETE
//     static readonly COLOR = Types.COLOR
//     static readonly MENU = Types.MENU
//     static readonly DATE = Types.DATE
//     static readonly TIME = Types.TIME
//     // static readonly DATE_EPOCH = Types.DATE_EPOCH
//     static readonly TIMESTAMP = Types.TIMESTAMP
//     static readonly OBJECT = Types.OBJECT
//     static readonly FICTION_OBJECT = Types.FICTION_OBJECT
//     static readonly OBJECTS_LIST = Types.OBJECTS_LIST
//     static readonly DIRECTORY = Types.DIRECTORY
//     static readonly TABLE = Types.TABLE
//
//     static has(value: any): boolean {
//         return Object.values(Types).includes(value)
//     }
// }
export type UUID = string
export type Timeout = ReturnType<typeof setTimeout>
export type Color = string
