/* eslint-disable no-console */
import {register} from 'register-service-worker'
// ServiceWorker runs in production mode only
if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, { // default process.env.BASE_URL = '/'
        ready() {
        },
        registered() {
        },
        cached() {
        },
        updatefound() {
            const message = {
                text: 'New updates are available. We will quickly reload your page, so you can start using the latest version.',
                options: {
                    type: 'info',
                }
            }
            window.app.$eventHub.$emit('notification', message)
        },
        updated() {
            console.warn('ServiceWorker: reloading page')
            localStorage.removeItem('params')
            sessionStorage.removeItem('params')
            window.location.reload(true)
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.')
        },
        error(error) {
            console.error('Error during service worker registration:', error)
        }
    })
} else {
    //for develop mode
    navigator.serviceWorker?.register('/extra-service-woreker.js')
}
