import Crud from '@/store/modules/crud.js'
import {toSelect} from "@/utils/helpers"

const URL = 'admin/data_unit_systems'

const unitSystems = new Crud(URL)

unitSystems.getters = () => ({
    getUnitSystemsToSelect: state => toSelect(state.data, 'label'),
    getUnitSystemsWithDefaultToSelect: state => {
        return toSelect([
            {id: null, label: 'Project default'},
            ...state.data
        ], 'label')
    },
})

export default unitSystems.module()
