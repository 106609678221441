import counties from './counties'
import countries from './countries'
import userStatuses from './user_statuses'
import companyStatuses from './company_statuses'
import companyTypes from './company_types'
import dataProviders from './data_providers'
import ssoProviders from './sso_providers'
import states from './states'
import northTypes from './north_types'
import errorSurface from './error_surface'

export default {
    namespaced: true,
    modules: {
        counties,
        countries,
        userStatuses,
        companyStatuses,
        companyTypes,
        states,
        dataProviders,
        ssoProviders,
        northTypes,
        errorSurface
    }
}
