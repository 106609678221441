import Crud from '@/store/modules/crud.js'
import RequestCacheManager from "@/utils/LocalCache"
import {apiCall} from "@/utils/api"
import {status} from "@/utils/helpers"

const URL = 'reports/subscriptions'
const subscriptions = new Crud(URL)

const actions = subscriptions.actions()
const mutations = subscriptions.mutations()

subscriptions.actions = () => ({
    ...actions,
    SEND_REPORT_NOW: ({commit}, {id}) => subscriptions._apiCall({commit}, {
        method: 'POST',
        url: `${URL}/${id}/test`,
        skipCommit: true,
    }),
    REQUEST_REPORT_TEMPLATES: () => RequestCacheManager.cache(apiCall, {
        method: 'GET',
        url: 'reports/mw_templates',
    })
        .then(resp => {
            return resp.data.data
        }),
    CLONE: ({commit}, {id}) => {
        return subscriptions._apiCall({commit}, {method: 'POST', url: `${URL}/${id}/clone`, position: 0}, 'CLONED')
    },
    SHARE: ({commit}, {id, data}) => {
        return subscriptions._apiCall({commit}, {method: 'POST', url: `${URL}/${id}/share_copy`, data, skipCommit: true})
    }
})
subscriptions.mutations = () => ({
    ...mutations,
    CLONED: (state, {data}) => {
        state.data.splice(0, 0, data)
        state.status = status({name: 'success', message: 'Cloned'})
    },
})
export default subscriptions.module()
