/* Helpers library */
import moment from "moment"
import {
    DEFAULT_DATETIME_FORMAT, GAUGE_HEIGHT,
} from "@/assets/constants.js"
import StaticAxios from "axios"
import seedrandom from "seedrandom"
import {AiControlType} from "@/core/types/ai-types"
// import AutocompleteEditor from '@/core/components/AgTable/editorTemplates/Autocomplete.vue'

// --- Common objs
export const result = (v, ...args) => {
    return typeof v === 'function' ? v(...args) : v
}

export const status = (obj) => {
    return {name: (obj && obj.name) ? obj.name : '', message: (obj && obj.message) ? obj.message : ''}
}

export const path = (obj, invert = false) => {
    const result = [];
    ['main_nav_title', 'second_nav_title', 'name'].forEach(key => {
        if (obj[key]) {
            result.push({text: obj[key], disabled: invert ? key === 'name' : key !== 'name'})
        }
    })
    return result
}

export const setPathItemKey = (arr = [], params = {}) => {
    /** params = {
          title1: {key: 'rig_name', disabled: false},
          title2: {key: 'well_name', disabled: true},
          ...
    } **/

    const paramsKeys = Object.keys(params)
    return arr.map(item => {
        const path = []
        paramsKeys.forEach(pathPart => {
            path.push({text: item[params[pathPart].key], disabled: params[pathPart].disabled})
        })
        return {...item, path}
    })
}

// export const internalState = (arr) => {
//     return {
//         isWitsml: arr.includes('witsml'),
//         isPlayer: arr.includes('player'),
//     }
// }

export const getColor = (theme, color) => {
    return theme.isDark ? theme.themes.dark[color] : theme.themes.light[color]
}

// export const toHCFormat = (sourceArray, sourceKeys) => sourceArray.map(item => sourceKeys.map(key => parseFloat(item[key])))

// --- Common functions
export function toSelect(data, textFieldName = 'name', filter = {}) {
    if (!_.isEmpty(filter)) {
        data = data.filter(item => item[filter.key] === filter.value)
    }
    data = _.sortBy(data, textFieldName)
    return typeof textFieldName === 'function' ? _.map(data, textFieldName) : data.map(item => ({
        text: item[textFieldName],
        value: item.id
    }))
}

export function rolesToSelect(roles = []) {
    const result = []
    const keyedData = {}
    roles.forEach((item) => keyedData[item.type] = [...(keyedData[item.type] || []), item])
    const keys = Object.keys(keyedData)
    keys.forEach((key, i) => {
        result.push({header: key}, ...keyedData[key])
        if (i !== keys.length - 1) result.push({divider: true})
    })
    return result
}

export function formatIfValid(date, params) {
    params = {
        format: DEFAULT_DATETIME_FORMAT,
        nullInsteadNow: true,
        keepLocalTime: false,
        ...params
    }
    let mDate = moment(date)
    if (date && mDate.isValid()) {
        return (mDate.utc(params.keepLocalTime).format(params.format))
    } else {
        return params.nullInsteadNow ? null : moment().utc(params.keepLocalTime).format(params.format)
    }
}

export function formatCell(value, format, params) {
    params = {
        emptySign: '-',
        multiplier: 1,
        unit: '',
        datetimeFormat: DEFAULT_DATETIME_FORMAT,
        keepLocalTime: false,
        isEmpty: v => {
            return !(v || v === 0)
        },
        ...params,
    }
    if (!format) return value
    format = format.split('.')
    let v
    const precisionDigits = Number(format[1])
    switch (format[0]) {
        case 'date':
            params.datetimeFormat = 'YYYY-MM-DD'
            return formatIfValid(value, {
                keepLocalTime: params.keepLocalTime,
                format: params.datetimeFormat
            }) || params.emptySign
        case 'datetime':
            return formatIfValid(value, {
                keepLocalTime: params.keepLocalTime,
                format: params.datetimeFormat
            }) || params.emptySign
        case 'float':
            v = parseFloat(value)
            if (params.isEmpty(v)) {
                return params.emptySign
            }
            if (isNaN(precisionDigits)) {
                v = v * params.multiplier
                v = Math.abs(v) < Number.EPSILON ? 0 : v
            } else {
                v = Math.round(Math.pow(10, precisionDigits) * v * params.multiplier) / Math.pow(10, precisionDigits)
                v = v.toFixed(precisionDigits)
            }
            return v + params.unit
        default:
            return value
    }
}

export function numPrecisionFormat(num, min, max, useGrouping = false) {
    const parsed = parseFloat(num)
    if (isNaN(parsed)) {
        return num
    }
    let formatted = parsed.toLocaleString('en-US', {
        useGrouping,
        minimumFractionDigits: min,
        maximumFractionDigits: max,
    })
    return useGrouping ? formatted : +formatted
}

export function formatWPSNumbers(arr) {
    if (!arr || arr.length === 0) return []
    const guarded = ['uid']
    return arr.map(item => {
        Object.keys(item).forEach(key => {
            if (!guarded.includes(key)) item[key] = numPrecisionFormat(item[key], 0, 3)
        })
        return item
    }
    )
}

export function headersTypeToControlType(ht) {
    let result = AiControlType.INT
    switch(ht) {
        case 'nonformatted':
            result = AiControlType.STRING
            break
        case 'float':
            result = AiControlType.NUMBER
            break
        case 'datetime':
            result = AiControlType.TIMESTAMP
            break
    }
    return result
}

// export const calculateContainerSize = (vm, innerEl) => {
//     const getYPaddings = (el) => {
//         return el ? parseFloat(window.getComputedStyle(el, null).getPropertyValue("padding-top")) + parseFloat(window.getComputedStyle(el, null).getPropertyValue("padding-bottom"))
//             : 0
//     }
//     const getXPaddings = (el) => {
//         return el ? parseFloat(window.getComputedStyle(el, null).getPropertyValue("padding-left")) + parseFloat(window.getComputedStyle(el, null).getPropertyValue("padding-right"))
//             : 0
//     }
//     return new Promise((resolve, reject) => {
//         let height, element, vCard, containerFluid, width
//         height = window.innerHeight - vm.$vuetify.application.top - vm.$vuetify.application.footer
//         element = document.getElementById(innerEl)
//         if (!element) {
//             reject('element not found')
//             return
//         }
//         const h2 = document.getElementsByTagName('h2')[0]

//         const toolbar = element.getElementsByClassName('v-toolbar')[0]
//         const toolbarDefaultHeight = window.app.$vuetify.breakpoint.smAndDown ? 56 : 64
//         height = height - (toolbar ? toolbar.offsetHeight : toolbarDefaultHeight) - (h2 ? h2.offsetHeight : 0)

//         vCard = element.getElementsByClassName('v-card__text')[0]
//         height -= getYPaddings(vCard)

//         containerFluid = document.getElementsByClassName('container container--fluid')[0]
//         height -= getYPaddings(containerFluid)
//         const col = element.parentElement
//         height -= getYPaddings(col)
//         const main = document.getElementsByTagName('main')[0]
//         width = main.clientWidth - getXPaddings(main) - (12 + 16) * 2

//         resolve({height, width})
//     })
// }

// export const stringAsNumericSorter = (a, b) => {
//     if (!isNaN(+a) && !isNaN(+b)) {
//         a = parseFloat(a)
//         b = parseFloat(b)
//     }
//     return a - b
// }

// export const castTo = (type, str) => {
//     if (str == null) return null
//     switch (type) {
//         case 'number':
//             return +str
//     }
//     return str
// }

/**
 * Notifications
 */
export const simpleNotification = (text, type = 'info') => {
    if (!text) return
    window.app.$eventHub.$emit('notification', {text, options: {type}})
}

export const deleteNotification = (data, itemsCount) => {
    const deleted = data?.data?.deleted || 0
    if (deleted === itemsCount) {
        simpleSuccessNotification(data.message)
    } else {
        simpleWarningNotification(data.message)
    }
}

export const simpleErrorNotification = event => {
    if (event instanceof StaticAxios.Cancel && !event.message) return
    simpleNotification(event, 'error')
}

export const simpleSuccessNotification = text => {
    simpleNotification(text, 'success')
}

export const simpleWarningNotification = text => {
    simpleNotification(text, 'warning')
}

export const transposeData = (source, keys) => {
    return source.map(item => (
        keys.length === 1 ? [null, _.get(item, keys[0], null)] : keys.map(key => _.get(item, key, null))
        // force value to yData by setting x to null
    ))
}

// export const convertPvaData = (sources, keys) => sources.map(source => ({
//     name: source.name,
//     data: transposeData(source.data, keys),
// }))
const random = seedrandom('mysuperrandomcolor')
export const getRandomColor = (brightness = 100) => {
    function randomChannel(brightness) {
        const r = 255 - brightness
        const n = 0 | ((random() * r) + brightness)
        const s = n.toString(16)
        return (s.length === 1) ? '0' + s : s
    }

    return '#' + randomChannel(brightness) + randomChannel(brightness) + randomChannel(brightness)
}

export const deselectAll = () => {
    if (window.getSelection) {
        window.getSelection().removeAllRanges()
    } else if (document.selection) {
        document.selection.empty()
    }
}
// check if is running in pwa mode (standalone mode set in vue.config.js)
export const isPwaMode = () => (navigator.standalone || window.matchMedia('(display-mode: standalone)').matches)

export const groupedList = (data, group, extra = {}) => {
    const options = {
        groupOrder: extra.groupOrder || [],
        sortBy: extra.sortBy || 'name',
    }
    return _.chain(data)
        .groupBy(group)
        .map((item, key) => ({
            groupName: key,
            items: [
                {header: key},
                {divider: true},
                ..._.sortBy(item, options.sortBy),
            ]
        }))
        .sortBy(item => options.groupOrder.indexOf(item.groupName))
        .map('items')
        .flatten()
        .value()
}

export const forceAlfaChannel = color => {
    let result = color
    const short = /^#(?:[0-9a-fA-F]{3})$/ //#ABC
    if (short.test(result)) {
        result = `${result}F`
    }
    const shortWithAlfa = /^#(?:[0-9a-fA-F]{4})$/ // #ABCD
    if (shortWithAlfa.test(result)) {
        result = '#' + _.chain(result)
            .split('')
            .drop()
            .map(char => _.repeat(char, 2))
            .join('')
            .value()
    }
    const long = /^#(?:[0-9a-fA-F]{6})$/ //#AABBCC
    if (long.test(result)) {
        result = `${result}FF`
    }
    return result
}

export const getLogFilter = data => ({
    start: data.min != null ? Math.round(+data.min) : null,
    end: data.max != null ? Math.ceil(+data.max) : null,
    is_global: data.is_global,
})

export function getInterpolatedYByX(x1, y1, x2, y2, x) {
    let y = undefined
    if (y1 != null && y2 != null) {
        y = y2 - (x2 - x) * (y2 - y1) / (x2 - x1)
    }
    return y
}

export const getInterpolatedSeriesValue = (series, index, x) => {
    const x1 = _.get(series, `data.${index - 1}.0`)
    const x2 = _.get(series, `data.${index}.0`)
    const y1 = _.get(series, `data.${index - 1}.1`, undefined)
    const y2 = _.get(series, `data.${index}.1`, undefined)
    return getInterpolatedYByX(x1, y1, x2, y2, x)
}

export const createHeadersWithUnits = (arr, headers, obj = {}) => {
    return arr.map(item => {
        const headerKeys = item.headerKey ? item.headerKey : item.value
        const header = _.get(headers, headerKeys)
        if (header) {
            return {
                type: header.field_type,
                unit: header.unit_label,
                ...obj,
                ...item,
            }
        } else {
            return {
                ...obj,
                ...item,
            }
        }
    })
}

export const createChartSeriesOptionsWithUnits = (arr, headers) => {
    return arr.reduce((acc, item) => {
        return {
            ...acc,
            [item.key]: _.get(headers, item.fieldName),
        }
    }, {})
}

export const updateItemInArray = (array, item, equalKeys = ['id']) => {
    const searchParams = equalKeys.reduce((acc, key) => ({...acc, [key]: item[key]}), {})
    const index = _.findIndex(array, searchParams)
    if (index === -1) {
        array.push(item)
    } else {
        array[index] = item
    }
    return array
}
export const isInRange = (start, end, value) => {
    start = start != null ? start : Number.NEGATIVE_INFINITY
    end = end != null ? end : Number.POSITIVE_INFINITY
    return value >= start && value <= end
}

export const clearSelection = () => {
    if (window.getSelection) {
        window.getSelection().removeAllRanges()
    } else { // old IE
        document.selection.empty()
    }
}

export function fileSize(bytes, si = true, dp = 1) {
    const thresh = si ? 1000 : 1024

    if (isNaN(+bytes)) {
        return ''
    }

    if (Math.abs(bytes) < thresh) {
        return Math.abs(bytes) + ' B'
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    let u = -1
    const r = 10 ** dp

    do {
        bytes /= thresh
        ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)


    return bytes.toFixed(dp) + ' ' + units[u]
}

export const getNodePath = (data, id, options) => {
    const defaultOptions = {
        idKey: 'id',
        childrenKey: 'children',
        ...options,
    }
    let result = []
    data.some(node => {
        if (node[defaultOptions.idKey] === id) {
            result = [node]
        } else if (node[defaultOptions.childrenKey]) {
            const nodePath = getNodePath(node[defaultOptions.childrenKey], id, options)
            if (nodePath.length > 0) {
                result = [node, ...nodePath]
            }
        }
        return !!result.length
    })
    return result
}

export const getUrl = (urlInfo = {}) => {
    let compiled = _.template(urlInfo.url)
    let result = compiled(urlInfo)
    const pattern1 = /\/{2,}/g //more than 1 slash
    const pattern2 = /\/$/g //last slash
    return result.replace(pattern1, '/').replace(pattern2, '')
}

export const getStandardGaugeHeight = (isMobile, hasRoadmap) => {
    if (isMobile) {
        return hasRoadmap ? GAUGE_HEIGHT.MOBILE_WITH_ROADMAP : GAUGE_HEIGHT.MOBILE_WITHOUT_ROADMAP
    } else {
        return hasRoadmap ? GAUGE_HEIGHT.DESKTOP_WITH_ROADMAP : GAUGE_HEIGHT.DESKTOP_WITHOUT_ROADMAP
    }
}

export function getElementTop(id) {
    const element = document.getElementById(id)
    if (element) {
        const bodyRect = document.body.getBoundingClientRect()
        const elemRect = element.getBoundingClientRect()
        return elemRect.top - bodyRect.top
    }
    return 0
}

export const isMobile = () => !!window.app.$vuetify.breakpoint.mobile

export const getValueIfHasMetadata = data => {
    return data?.hasMetadata ? data.value : data
}

/**
 * @param {string|string[]|null} string
 */
export const htmlEncode = function (string) {
    if (!string) return ''
    if(Array.isArray(string)) {
        return string.map(item => {
            return item.replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/'/g, '&#39;')
                .replace(/"/g, '&#34;')
                .replace(/\//, '&#x2F;')
        })
    } else {
        return string.replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/'/g, '&#39;')
            .replace(/"/g, '&#34;')
            .replace(/\//, '&#x2F;')
    }
}

/**
 * @param {string} searchText
 * @param {Record<string, any>[]} data
 * @param {Record<string, any>[]} cols
 * @param {string} key
 */
export const searchInTable = function (searchText, data, cols, key = 'colId') {
    data = data.filter(obj => {
        for(const col of cols) {
            const id = _.get(col, key, '')
            let val = obj[id]
            if (val === undefined || val === null) {
                continue
            }

            if (col.valueFormatter) {
                val = col.valueFormatter({
                    colDef: col,
                    value: val
                })
            }

            const v = typeof val !== 'string' ? (val + '').toUpperCase() : val.toUpperCase()
            if (v && v.includes(searchText.toUpperCase())) return true
        }

        return false
    })

    return data
}

export const swapArrayElements = function (items, from, to) {
    if (
        from < 0 || to < 0 ||
        from >= items.length || to >= items.length
    ) {
        throw Error('Invalid index passed to swapArrayElements()')
    }
    const newArray = [...items]
    const temp = newArray[from]
    newArray[from] = newArray[to]
    newArray[to] = temp
    return newArray
}
