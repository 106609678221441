import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import _ from 'lodash'
import './registerServiceWorker'
import 'ag-grid-enterprise'
import Highcharts from 'highcharts'
import FocusDirective from "@/directives/focus"
import HotkeyManager from "@/plugins/HotkeyManager"
import {Component} from "vue-property-decorator"
import PortalVue from 'portal-vue'
import {LicenseManager} from "ag-grid-enterprise"

LicenseManager.setLicenseKey(process.env.VUE_APP_AG_GRID_KEY)

import hcMoreInit from 'highcharts/highcharts-more'
hcMoreInit(Highcharts)

Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate'
])

Vue.config.productionTip = false

Vue.prototype._ = _

Vue.prototype.$eventHub = new Vue() // Global event bus
/**
 * Global events list:
 *   period-changed
 *   selected-well-changed
 *   notification
 *   route-change-requested
 *   route-change-confirmed
 *   route-changed
 *   drawer-toggled
 *   show-drawer
 *   set-drawer-model
 *   reflow-charts
 *   show-confirm-dialog
 *   toggle-rt-player
**/

Vue.use(HotkeyManager, Vue.prototype)
Vue.use(PortalVue)
Vue.directive('focus', FocusDirective)

window.app = new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app')
