import Crud from '@/store/modules/crud.js'

const URL = 'references/counties'

const counties = new Crud(URL)

counties.getters = () => ({
    getCountiesToSelect: state => state.data,
})

export default counties.module()
