import Crud from '@/store/modules/crud.js'

const URL = 'external_accounts'

const externalAccounts = new Crud(URL)

externalAccounts.getters = () => ({
    getExternalAccounts: state => state.data,
})

export default externalAccounts.module()
