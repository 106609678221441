import Crud from '@/store/modules/crud.js'

const URL = 'admin/user_groups/${group_id}/users'

const groups = new Crud(URL)

groups.getters = () => ({
    getUsers: state => state.data,
    // getRolesToSelect: state => toSelect(state.data),
})

export default groups.module()
