const brand = Object.freeze({
    base: '#e11936',
    // base: '#f44336',
    // lighten5: '#ffebee',
    // lighten4: '#ffcdd2',
    // lighten3: '#ef9a9a',
    // lighten2: '#e57373',
    // lighten1: '#ef5350',
    // darken1: '#e53935',
    // darken2: '#d32f2f',
    // darken3: '#c62828',
    // darken4: '#b71c1c',
    // accent1: '#ff8a80',
    // accent2: '#ff5252',
    // accent3: '#ff1744',
    // accent4: '#d50000'
})
const white = Object.freeze({
    base: '#fff',
    lighten1: '#f1f1f1',
    lighten2: '#8f8e8e',
})

const black = Object.freeze({
    base: '#000',
    lighten1: '#101010',
    lighten2: '#969595',
})

export default Object.freeze({
    brand,
    white,
    black,
})
