import {UUID} from "@/core/types/ai-types"
import store from "@/store"
import {PERIOD_SOURCE_TYPES} from "@/assets/enums"
import {AiWell, WellPeriod} from "@/core/types/ai-interfaces"
import {Nullable} from "@/types/common"
import {WELL_STATUSES} from "@/assets/constants"
const splitter = '___'

export type ChannelUUID = `${UUID}${typeof splitter}${UUID}`

export const channelUuid = (wellId: UUID, channelId: UUID): ChannelUUID => {
    const wellKey = store.getters.getSelectedWellId === wellId ? 'current_well' : wellId
    return `${wellKey}${splitter}${channelId}`
}

interface CalcLookAheadArgs {
    value: number
    period: WellPeriod
}
export const calcLookAhead = ({value, period}: CalcLookAheadArgs) => {
    let lookAhead = 0
    const periodType = _.get(store.getters.getStoredPeriod, 'source.type')
    //@ts-ignore
    if (store.getters.getAppMode !== WELL_STATUSES.completed.name) {
        if (periodType === PERIOD_SOURCE_TYPES.buttonWell || periodType === PERIOD_SOURCE_TYPES.buttonToday) {
            lookAhead = value
        } else if (periodType === PERIOD_SOURCE_TYPES.filter) {
            if (period.autoUpdate) {
                lookAhead = value
            }
        } else {
            lookAhead = !period.autoUpdate ? 0 : value
        }
    }
    return lookAhead
}

export function getLocale(well?: Nullable<Partial<AiWell>>) {
    const wellLocale = _.get(well, 'locale', {})
    const userLocale = store.getters.getUserLocale || {}
    return _.isEmpty(wellLocale) ? userLocale : wellLocale
}
