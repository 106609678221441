import wells from './wells'
import roles from './roles'
import groups from './groups'
import users from './users'
import userRoles from './userRoles'
import groupRoles from './groupRoles'

export default {
    namespaced: true,
    modules: {
        wells,
        roles,
        groups,
        users,
        userRoles,
        groupRoles,
    }
}
