import dateFormats from './date_formats'
import timeFormats from './time_formats'
import unitSystems from './unit_systems'

export default {
    namespaced: true,
    modules: {
        dateFormats,
        timeFormats,
        unitSystems,
    }
}
