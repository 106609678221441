import Crud from '@/store/modules/crud.js'

const URL = 'admin/users/${user_id}/wells/users'

const wells = new Crud(URL)

wells.getters = () => ({
    getWells: state => state.data,
})

export default wells.module()
