import Crud from '@/store/modules/crud.js'
import {toSelect} from "@/utils/helpers"

const URL = 'references/user_statuses'

const company_statuses = new Crud(URL)

company_statuses.getters = () => ({
    getUserStatusesToSelect: state => toSelect(state.data, 'title'),
})

export default company_statuses.module()
