export default class Enum {
    constructor(data, additionalOptions) {
        const options = {
            key: 'name',
            defaultReturnProperty: null,
            ...additionalOptions
        }
        this.all = data
        data.forEach(item =>
            Object.defineProperty(this, item[options.key], {
                get: () => {
                    const result = data.find(obj => obj[options.key] === item[options.key])
                    return options.defaultReturnProperty ? result[options.defaultReturnProperty] : result
                }
            }))
    }

    toArray(mapping = {}, options = {}) {
        const defaultOptions = {
            onlyMapped: false, // if true, returns only mapped properties
            ...options,
        }
        return this.all
            .map(item => Object.keys(item)
                .reduce((acc, key) => {
                    const newKey = mapping[key]
                    return (newKey !== undefined || !defaultOptions.onlyMapped) ?
                        {...acc, [newKey || key]: item[key]} :
                        acc
                }, {}))
    }
}
