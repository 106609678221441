import {
    WPS_DATA_ERROR,
    WPS_DATA_REQUEST,
    WPS_DATA_SUCCESS,
    WPS_PROXIMITY_DATA_REQUEST, WPS_SEPARATION_DATA_REQUEST,
    WPS_STATE_RESET,
} from '@/store/actions/wps'
import {apiCall, hasError} from '@/utils/api'
import {
    formatWPSNumbers,
    status,
} from '@/utils/helpers'
import Vue from 'vue'
// import {WPS_BUILDWALK_REQUEST} from "@/store/actions/wps"
import store from "@/store"

const getDefaultState = () => {
    return {
        buildwalk: {data: [], headers: {}, status: status()},
    }
}

const validate = () => {
    if (!store.getters.isSelectedWellActual) {
        console.error('Well is not actual!')
        return false
    }
    return true
}

const state = getDefaultState()

const getters = {
    getBuildWalkData: state => state.buildwalk.data.map(item => ({...item, mod_tf: item.tf + 180})),
    getBuildWalkHeaders: state => state.buildwalk.headers,
}

const actions = {
    [WPS_SEPARATION_DATA_REQUEST]: ({commit}, payload) => {
        if (!validate() || !store.getters.getSelectedWellId) {
            return Promise.resolve({})
        }

        const what = {
            method: 'POST',
            url: `/wells/${store.getters.getSelectedWellId}/wps/travelling_cylinder/survey`,
            data: payload,
        }

        return apiCall(what)
            .catch(err => hasError({commit}, err))
    },
    [WPS_PROXIMITY_DATA_REQUEST]: ({commit}, payload) => {
        if (!validate() || !store.getters.getSelectedWellId) {
            return Promise.resolve({})
        }

        const what = {
            method: 'POST',
            url: `/wells/${store.getters.getSelectedWellId}/wps/ladder_plot`,
            data: payload,
        }

        return apiCall(what)
            .catch(err => hasError({commit}, err))
    },
    // [WPS_BUILDWALK_REQUEST]: ({commit}, {dataType, realtime}) => {
    //     if (!validate()) {
    //         return Promise.resolve({})
    //     }
    //     if (!realtime) {
    //         commit(WPS_DATA_REQUEST, dataType)
    //     }
    //
    //     return apiCall({method: 'POST', url: 'wps/buildwalk', data: {data_filter: store.getters.getActivePeriod}})
    //         .then(resp => commit(WPS_DATA_SUCCESS, {type: dataType, data: resp.data.data.points, headers: resp.data.headers}))
    //         .catch(err => {
    //             commit(WPS_DATA_ERROR, {type: dataType})
    //             hasError({commit}, err)
    //         })
    // },
}

const mutations = {
    [WPS_STATE_RESET]: (state, keys = []) => {
        if (keys.length > 0) {
            keys.forEach(key => {
                state[key] = {data: [], status: status()}
                if (state[key]) {
                    state[key].id = null
                }
            })
        } else {
            Object.assign(state, getDefaultState())
        }
    },
    [WPS_DATA_REQUEST]: (state, key) => {
        Vue.set(state[key], 'status', status({name: 'loading'}))
    },
    [WPS_DATA_SUCCESS]: (state, wpsData) => {
        state[wpsData.type].data = Object.freeze(formatWPSNumbers(wpsData.data))
        state[wpsData.type].headers = Object.freeze(wpsData.headers)
        Vue.set(state[wpsData.type], 'status', status({name: 'success', message: 'Data loaded successfully'}))
        state[wpsData.type].id = wpsData.data.id || null
        state[wpsData.type].vs_azimuth = wpsData.data.vs_azimuth
    },
    [WPS_DATA_ERROR]: (state, param) => {
        Vue.set(state[param.type], 'data', [])
        Vue.set(state[param.type], 'status', status({
            name: 'error',
            message: param.err ? param.err.message : 'Unknown error!'
        }))
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
