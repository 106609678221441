import Vue from 'vue'
import Vuex from 'vuex'
import history from './modules/history'
import user from './modules/user'
import auth from './modules/auth'
import data from './modules/data'
import wps from './modules/wps'
import params from './modules/params'
import admin from './modules/admin'
import crud from './modules/crud/index'
import widgets from "./modules/widgets"
import requests from "./modules/requests"
import fileDrive from "./modules/file_drive"
import settings from "./modules/settings"
import equipment from "./modules/equipment"

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    namespaced: true,
    modules: {
        history,
        user,
        auth,
        fileDrive,
        data,
        wps,
        admin,
        params,
        widgets,
        requests,
        settings,
        crud,
        equipment,
    },
    strict: debug,
})
