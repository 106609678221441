import Crud from '@/store/modules/crud.js'
import {toSelect} from "@/utils/helpers"

const URL = 'references/error_surface'

const errorSurface = new Crud(URL)

errorSurface.getters = () => ({
    getErrorSurfaceToSelect: state => toSelect(state.data, 'name')
})

export default errorSurface.module()
