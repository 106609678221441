import groups from './groups'
import groupUsers from './group_users'

export default {
    namespaced: true,
    modules: {
        groups,
        groupUsers,
    }
}
