const saveToStorage = (state) => {
    sessionStorage.setItem('settings', JSON.stringify(state))
    localStorage.setItem('settings', JSON.stringify(state))
}

const getStorage = () => {
    return JSON.parse(sessionStorage.getItem('settings')) || JSON.parse(localStorage.getItem('settings')) || {}
}

const state = getStorage()

const getters = {
    getSettings: state => state,
}

const actions = {}

const mutations = {
    SAVE: (state, payload) => {
        if (payload.componentName) {
            state[payload.componentName] = payload.settings
            saveToStorage(state)
        }
    },
}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
