import store from "@/store"
import {LOAD_WIDGET} from "@/store/actions/widgets"
import {Widget, widgetsDictionary} from "@/utils/WidgetFactory/widgetsDictionary"
import WidgetLoadingError from "@/errors/WidgetLoadingError"
import {LAYOUT_MODES, PERMISSIONS} from "@/assets/enums"
import {BaseWidgetModel, WidgetParams} from "@/models/abstract/BaseWidgetModel"
import ThrowableMap from "../ThrowableMap"
import {UUID} from "@/core/types/ai-types"
import {CommonDataModel} from "@/models/abstract/CommonDataModel"
import {AiWell, WellPeriod} from "@/core/types/ai-interfaces"
import {Nullable} from "@/types/common"
import {ILocale} from "@/core/types/well"

export interface WidgetOptions {
    filteredByWell?: boolean
    configuration: Record<string, any>
    layoutMode?: Array<LAYOUT_MODES>
    saveData: (dataModel: object) => Promise<void>
    well?: Nullable<AiWell>
    wellId?: Nullable<UUID>
    period?: Partial<WellPeriod>
    locale?: ILocale
    hasPermission?: (_: PERMISSIONS) => boolean
    canApplyToAll?: boolean
}

export interface ApplicationApi {
    selectedWellId?: UUID
}
export interface WidgetFactoryCreateParams extends WidgetOptions {
    fragmentId: UUID
}
export default class WidgetFactory {
    static classMap = new ThrowableMap<unknown, Widget>()
    static create(options: WidgetFactoryCreateParams) {
        return new Promise<BaseWidgetModel>(resolve => {
            store.dispatch(LOAD_WIDGET, options).then((widget: any) => {
                if (!widget) {
                    throw new WidgetLoadingError(`widget[${options.fragmentId}] not found`)
                }
                WidgetFactory.getModelClass(widget.params.class).then(async modelClass => {
                    resolve(await createModelEntity(modelClass, options, widget.params))
                })
            })
        })
    }

    static async getModelClass(clazz: Widget) {
        const loadClass = _.get(widgetsDictionary[clazz], 'import')
        if (!loadClass) {
            throw new WidgetLoadingError(`"${clazz}" not found in widgetsDictionary`)
        }
        try {
            const modelClass = await loadClass()
            if (!WidgetFactory.classMap.has(modelClass.default)) {
                WidgetFactory.classMap.set(modelClass.default, clazz)
            }
            return modelClass.default
        } catch (e) {
            throw new WidgetLoadingError(`class: ${clazz}, module import error, ${e}`)
        }
    }
    
}

export interface WidgetModelCtorArg<ConfigurationType extends object = object> {
    fragmentId: UUID
    params: WidgetParams<ConfigurationType>
    options: WidgetOptions
}
// TODO: implement all widget models with WidgetModelCtor
interface WidgetModelCtor<T> {
    new (_:WidgetModelCtorArg): T
    create?(_:WidgetModelCtorArg): Promise<T>
}
export async function createModelEntity<T = BaseWidgetModel<CommonDataModel>>(ModelClass: WidgetModelCtor<T>, options: WidgetFactoryCreateParams, widgetParams: WidgetParams) {
    const params = _.cloneDeep(widgetParams)
    const {fragmentId} = options
    if (ModelClass.create) {
        return await ModelClass.create({fragmentId, params, options})
    }
    return new ModelClass({fragmentId, params, options})
}
