import Crud from '@/store/modules/crud.js'
import {toSelect} from "@/utils/helpers"

const URL = 'references/data_providers'

const dataProviders = new Crud(URL)

dataProviders.getters = () => ({
    getDataProvidersToSelect: state => toSelect(state.data, 'title'),
})

export default dataProviders.module()
