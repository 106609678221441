import {
    DATA_ERROR,
    DATA_REQUEST,
    DATA_STATE_RESET,
    DATA_SUCCESS,
    ROADMAP_DATA_SUCCESS,
    ROADMAP_REQUEST,
    END_LOAD,
} from '../actions/data'
import {AxiosError} from "axios"
import {apiCall, hasError} from '@/utils/api'
import {
    simpleErrorNotification,
    simpleSuccessNotification,
    status
} from '@/utils/helpers'
import Vue from 'vue'
import store from "@/store"
import {AUTH_LOGOUT} from '../actions/auth'
import {CUSTOM_REQUEST, DATA_SET, GAUGE_DATA_STORE} from "@/store/actions/data"
import {addUnit} from "@/utils/functions.ts"

const getDefaultState = (saveState) => {
    return Object.assign({
        charts: {
            data: {},
            headers: {},
            status: status(),
        },
        roadmap: {
            data: [],
            headers: {},
            status: status(),
        },
        gauges: {
            data: {},
            status: status(),
        }
    }, saveState)
}

const state = getDefaultState()

const getters = {
    getRotaryData: state => _.get(state, 'charts.data.RotaryData', []),
    getTrippingAnalysis: state => _.get(state, 'charts.data.TrippingAnalysisChart', []),
    getZeroWobData: state => _.get(state, 'charts.data.ZeroWobChartData', []),
    getZeroDiffData: state => _.get(state, 'charts.data.ZeroDiffChartData', []),
    getTrippingConnectionsAnalysis: state => _.get(state, 'charts.data.TrippingConnectionsAnalysisChart', []),
    getDrillingDistancePercentage: state => ({
        rotary: _.get(state, 'charts.data.rotary_distance_percentage',),
        slide: _.get(state, 'charts.data.slide_distance_percentage',),
    }),
    getDrillingTimePercentage: state => ({
        rotary: _.get(state, 'charts.data.rotary_time_percentage',),
        slide: _.get(state, 'charts.data.slide_time_percentage',),
    }),
    getConnectionsHistogramData: state => {
        const connectionsHistogramData = state.charts.data.ConnectionsHistogramData
        if (!connectionsHistogramData || !connectionsHistogramData.length) return []
        const maxLength = connectionsHistogramData.reduce((acc, item) => Math.max(acc, item.data.length), 0) - 1

        const totalRigGoal = _.get(state, 'charts.data.ConnectionsStatData.data', [])
            .reduce((acc, item) => isNaN(+item.rig_goal) ? acc : acc + item.rig_goal, 0)
        const max_time = _.get(state, 'charts.data.ConnectionsStatData.max_time', 0)
        const median_time = _.get(state, 'charts.data.ConnectionsStatData.median_time', 0)

        return [...connectionsHistogramData,
            {
                name: 'rig_goal',
                headers: _.get(state, 'charts.headers.rig_goal'),
                data: [
                    [0, totalRigGoal],
                    [maxLength, totalRigGoal]
                ]
            },
            {
                name: 'max_time',
                headers: _.get(state, 'charts.headers.max_time'),
                data: [
                    [0, max_time],
                    [maxLength, max_time]
                ]
            },
            {
                name: 'median_time',
                headers: _.get(state, 'charts.headers.median_time'),
                data: [
                    [0, median_time],
                    [maxLength, median_time]
                ]
            },
        ]
    },
    getConnectionsDistributionData: state => _.get(state, 'charts.data.ConnectionsDistributionData', {}),
    getConnectionsStatData: state => {
        const statisticsData = _.get(state, 'charts.data.ConnectionsStatData.data', [])
        const unit = _.get(state, 'charts.headers.stand_avg.unit_label')
        return statisticsData.length === 0 ? []
            : [
                ...statisticsData.map(item => ({
                    ...item,
                    label: addUnit(item.label, unit),
                })),
            ]
    },
    getActivitiesStat: () => _.get(state, 'charts.data.ActivitiesStatData', []),
    getChartHeaders: state => {
        return state.charts.headers
    },
    getRoadmapHeaders: state => {
        return state.roadmap.headers
    }
}
const actions = {
    [DATA_REQUEST]: ({commit}, params) => {
        let payload = {...store.getters.getDataRequestParams}
        if (_.isEmpty(payload)) {
            const err = {message: 'Empty request params!'}
            commit(DATA_ERROR, {key: 'charts', error: err})
            console.error(err.message)
            return
        }
        if (!payload.well_id) {
            const err = {message: 'No well selected!'}
            commit(DATA_ERROR, {key: 'charts', error: err})
            console.error(err.message)
            return
        }
        if (params.filteredByWell) {
            let selectedWell = store.getters.getSelectedWell
            payload.start_date = selectedWell.start_date
            payload.end_date = selectedWell.end_date
            payload.start_depth = selectedWell.start_depth
            payload.end_depth = selectedWell.end_depth
        }
        const period = store.getters.getStoredPeriod
        payload.typesFilter = params.typesFilter
        if (!period.autoUpdate && !params.realtime) {
            commit(DATA_REQUEST, 'charts')
        }
        // todo refactor to get method
        //TODO: remove "...payload" after back refactoring
        return new Promise((resolve, reject) => {
            apiCall({
                method: params.method || 'post',
                url: params.url,
                data: {...payload, filter: payload, ...params.data},
            })
                .then(resp => {
                    commit(DATA_SUCCESS,
                        {
                            data: resp.data.data,
                            headers: resp.data.headers,
                            storeTo: params.storeTo,
                            keysMap: params.keysMap,
                        })
                    commit(END_LOAD, 'charts')
                    resolve(resp)
                })
                .catch(err => {
                    commit(DATA_ERROR, {key: 'charts', error: err})
                    hasError({commit}, err)
                    reject(err)
                })
        })
    },

    [ROADMAP_REQUEST]: ({commit, getters}, params = {method: 'get', data: undefined}) => {
        const method = params.method
        const data = params.data
        const id = store.getters.getSelectedWell.id
        // todo рефакторинг обработки ошибок
        if (!id) {
            const err = {message: 'No well selected!'}
            commit(DATA_ERROR, {key: 'roadmap', error: err})
            console.error(err.message)
            return
        }
        if (!+getters.getSelectedWellParam('roadmap')) {
            const err = {message: 'Roadmap is not allowed for selected well!'}
            commit(DATA_ERROR, {key: 'roadmap', error: err})
            console.error(err.message)
            return
        }
        const url = 'wells/' + id + '/roadmap'
        commit(DATA_REQUEST, 'roadmap')
        return new Promise((resolve, reject) => {
            apiCall({method, url: url, data: {data}})
                .then(resp => {
                    commit(ROADMAP_DATA_SUCCESS, {data: resp.data.data, headers: resp.data.headers})
                    commit(END_LOAD, 'roadmap')
                    resolve(resp)
                })
                .catch(err => {
                    // if err is unauthorized, logout, to
                    if (err.status === 401) {
                        commit(AUTH_LOGOUT)
                    } else {
                        commit(DATA_ERROR, {key: 'roadmap', error: err})
                    }
                    reject(err)
                })
        })
    },

    [CUSTOM_REQUEST]: ({commit}, params) => {
        const method = params.method || 'post'
        return new Promise((resolve, reject) => {
            apiCall({
                method,
                url: params.url,
                data: params.data,
                params: params.params,
                headers: {'request-id': params.requestId},
                requestId: params.requestId,
            })
                .then(resp => {
                    if (!params.hideNotifications && !params.hideSuccessNotification) simpleSuccessNotification(resp.data.message)
                    resolve(resp)
                })
                .catch(err => {
                    switch (err.status) {
                        case 401:
                            commit(AUTH_LOGOUT)
                            break
                        case 404:
                            if (!params.hideNotifications && !params.hideErrorNotification) simpleErrorNotification(`URL ${params.url} not found`)
                            break
                        case 503:
                            if (err.message.code !== AxiosError.ERR_CANCELED) {
                                if (!params.hideNotifications && !params.hideErrorNotification) simpleErrorNotification(err.data.message)
                            }
                            break
                        default:
                            if (!params.hideNotifications && !params.hideErrorNotification) simpleErrorNotification(err.data.message)
                    }
                    reject(err)
                })
        })
    },
}

const mutations = {
    [DATA_STATE_RESET]: (state, savedKeys) => {
        let savedState = null
        if (savedKeys) {
            savedState = {}
            for (let i = 0; i < savedKeys.length; i++) {
                const value = _.get(state, savedKeys[i])
                _.set(savedState, savedKeys[i], value)
            }
        }
        Object.assign(state, getDefaultState(savedState))
    },
    [DATA_SET]: (state, params) => {
        _.set(state, params.key, params.value)
    },
    [DATA_REQUEST]: (state, key) => {
        Vue.set(state[key], 'status', status({name: 'loading'}))
    },
    [GAUGE_DATA_STORE]: (state, payload) => {
        Vue.set(state.gauges, 'data', payload.data.data)
    },
    [DATA_ERROR]: (state, payload) => {
        Vue.set(state[payload.key], 'data', [])
        Vue.set(state[payload.key], 'status', status({name: 'error', message: payload.error.message || ''}))
    },
    [DATA_SUCCESS]: (state, params) => {
        let data = {}
        if (params.keysMap) {
            Object.keys(params.data).forEach(key => {
                if (params.keysMap[key]) {
                    data[params.keysMap[key]] = params.data[key]
                } else {
                    data[key] = params.data[key]
                }
            })
        } else if (params.storeTo) {
            data[params.storeTo] = params.data
        } else {
            data = params.data
        }
        const dataKeys = Object.keys(data);
        [
            'RotaryData',
            'DrillingDistanceHistogramData',
            'DrillingROPHistogramData',
            'DrillingTimeHistogramData',
            'ConnectionsHistogramData',
            'ActivitiesByDayHistogramData',
        ].forEach(key => {
            if (dataKeys.includes(key)) {
                data[key] = Object.freeze(data[key])
            }
        })
        if (dataKeys.includes('Roadmap')) {
            Vue.set(state.roadmap, 'data', data.Roadmap)
        }
        Vue.set(state.charts, 'headers', {...state.charts.headers, ...params.headers})
        Vue.set(state.charts, 'data', {...state.charts.data, ...data})
    },
    [ROADMAP_DATA_SUCCESS]: (state, data) => {
        state.roadmap.headers = Object.freeze(data.headers)
        state.roadmap.data = Object.freeze(data.data)
    },
    [END_LOAD]: (state, key) => {
        Vue.set(state[key], 'status', status({name: 'success', message: 'Data loaded successfully'}))
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
