import {
    REQUEST_AC_REPORT,
    REQUEST_AC_SUMMARY_REPORT,
    REQUEST_BACK_TO_PLAN,
    REQUEST_BACK_TO_PLAN_POINTS,
    REQUEST_FACT_POINTS,
    REQUEST_PLAN_POINTS,
    SEARCH_EDM_OFFSETS,
    SEARCH_WELL_PLANNING_EDM_OFFSETS,
    REQUEST_PROJECTION,
    REQUEST_PROJECTIONS,
    REQUEST_PROJECTIONS_POINTS,
    REQUEST_PROJECTIONS_TREE,
    REQUEST_TITLE_LIST,
    WPS_CHART_REQUEST,
    FILE_UPLOAD,
    FILE_DOWNLOAD,
    REQUEST_REPORT,
    SHRINK_AUDIT,
    RIGS_FOR_ALERT,
    REQUEST_TF,
    REQUEST_MULTI_WELL_DATA,
    RECALCULATE_PERIOD,
    REQUEST_FILTERED_WELLS_LIST,
    REQUEST_FILTERED_MULTI_WELL_LIST,
    REQUEST_MW_REPORT,
    REQUEST_AC_LOOKAHEAD_DESIGNS,
    REQUEST_REFERENCE_DESIGNS,
    REQUEST_FILTERED_WELLS_NUMBER,
    REQUEST_MULTI_WELL_DVD,
    REQUEST_SINGLE_WELL_DVD,
    REQUEST_FAVORITES,
    CHANGE_FAVORITE_WELL,
    CHANGE_FAVORITE_RIG,
    REQUEST_RECENT_WELLS,
    REQUEST_ALL_RIGS,
    RESET_WITSML,
    RESET_CORRECT_SURVEYS,
    REQUEST_SLIDESHEET,
    REQUEST_MAP_SETTINGS,
    REQUEST_HEATMAP_DATA,
    REQUEST_FEATURE_INFO,
    REQUEST_TARGET_WINDOWS_POINTS,
    REQUEST_INTERPRETATION_POINTS,
    REQUEST_AVAILABLE_WELL_FORMATIONS,
    REQUEST_READY_FOR_SHIPMENT_TOOLS,
    REQUEST_STATISTICS_LIST,
    REQUEST_BIT_RUN_STATISTIC,
    REQUEST_STATISTICS_DATA,
    REQUEST_COMPANY_USERS,
    REQUEST_SHARE_WIDGET,
    REQUEST_TARGET_LINE_POINTS,
    REQUEST_ALERT_INCIDENT,
    REQUEST_FILTERED_OFFSET_LIST,
    REQUEST_ROTARY_TENDENCY,
    REQUEST_ACTIVE_WELLS_LIST,
    REQUEST_TIMELOG,
    REQUEST_FD_CATEGORIES_FOR_ALERTS,
    REQUEST_RUNS_FOR_LOGS,
    REQUEST_RUN_DETAILS,
    REQUEST_TRIPPING_DATA,
    REQUEST_AXES_SCATTER_PLOT,
    REQUEST_AC_RISK,
    REQUEST_HEATMAP_SMART_PARAMS,
    RELOAD_PERIOD,
    REQUEST_COMMENTS_FOR_LOGS,
    REQUEST_COMMENT_FOR_LOGS,
    REQUEST_PROTOTYPES_POINTS,
    CREATE_COMMENT_FOR_LOGS,
    REQUEST_INCIDENTS_FOR_LOGS,
    REQUEST_INCIDENT_FOR_LOGS,
    SET_INCIDENT_FOR_LOGS,
    REQUEST_RIG_MESSAGES_FOR_LOGS,
    REQUEST_RIG_MESSAGE_FOR_LOGS,
    REQUEST_DEPTH_BY_FIELD,
    REQUEST_TECH_LIMIT_AGGREGATIONS,
    REQUEST_RECOMMENDATIONS_FOR_LOGS,
    REQUEST_RECOMMENDATION_FOR_LOGS,
    SET_RECOMMENDATION_FOR_LOGS,
    GET_SECTION_OPERATIONS,
    REQUEST_VARIABLES,
    REQUEST_COST_VS_DEPTH,
    REQUEST_COST_VS_DEPTH_DVD,
    REQUEST_RIG,
} from "@/store/actions/requests"
import {_apiCall, apiCall} from "@/utils/api"
import store from "@/store"
import {SURVEY_TYPE} from "@/assets/constants.js"
import {simpleErrorNotification} from "@/utils/helpers"
import {getUrl} from "@/utils/helpers"
import {compileUrl} from "@/utils/functions.ts"
import scenarios from "./scenarios"
import format from "@/utils/format"

const requestSurvey = ({wellId, type, interpolate, deviation, ellipses, from_md}) => !wellId ?
    Promise.reject() :
    apiCall({
        method: 'GET',
        url: 'wps/surveys',
        params: {type: type.name, well_id: wellId, interpolate, deviation, ellipses, from_md}
    }).catch(err => {
        simpleErrorNotification(err.data.message)
    })

const getFileNameFromHeader = (header) => {
    const rawFileName = header.split("filename*=utf-8''")[1] || ''
    const fileName = header.split("filename=")[1] || 'file'
    return decodeURIComponent(rawFileName) || fileName
}

const actions = {
    [REQUEST_PLAN_POINTS]: ({_commit}, payload = {}) => requestSurvey({wellId: payload.wellId, type: SURVEY_TYPE.PLAN, interpolate: payload.interpolate, ellipses: payload.ellipses, from_md: payload.from_md}),
    [REQUEST_FACT_POINTS]: ({_commit}, payload = {}) => requestSurvey({wellId: payload.wellId, type: SURVEY_TYPE.FACT, interpolate: payload.interpolate, deviation: true, ellipses: payload.ellipses, from_md: payload.from_md}),
    [REQUEST_PROTOTYPES_POINTS]: async ({_commit}, {params, wellId}) => apiCall({
        method: 'GET',
        url: await compileUrl('wells/${well_id}/surveys/prototypes', {
            well_id: wellId,
        }),
        params,
    }),
    [REQUEST_INTERPRETATION_POINTS]: async ({_}, payload) => apiCall({
        method: 'GET',
        url: `geosteering/${store.getters.getSelectedWellId}/interpretation_points`,
        params: payload
    }),
    [REQUEST_TARGET_WINDOWS_POINTS]: async (ctx, {wellId, from_md, url}) => {
        const definedUrl = url || 'target_window_points'
        return apiCall({
            method: 'GET',
            url: `geosteering/${wellId}/${definedUrl}`,
            params: from_md !== undefined ? {
                from_md,
            } : {}
        })
    },
    [REQUEST_TARGET_LINE_POINTS]: async ({_}, {params, wellId}) => apiCall({
        method: 'GET',
        url: `geosteering/${wellId}/target_line_points`,
        params,
    }),
    [REQUEST_BACK_TO_PLAN_POINTS]: async ({_}, {params, wellId}) => apiCall({
        method: 'GET',
        url: await compileUrl('wells/${well_id}/back_to_plan/current/points', {
            well_id: wellId,
        }),
        params,
    }),
    [REQUEST_PROJECTIONS_POINTS]: async ({_}, {params, wellId}) => apiCall({
        method: 'GET',
        url: await compileUrl('wells/${well_id}/projections/points', {
            well_id: wellId,
        }),
        params,
    }),
    [REQUEST_PROJECTIONS]: async () => apiCall({
        method: 'GET',
        url: await compileUrl('wells/${well_id}/projections', {
            well_id: store.getters.getSelectedWellId,
        }),
    }),
    [REQUEST_PROJECTION]: async ({_}, payload) => apiCall({
        method: 'GET',
        url: await compileUrl('wells/${well_id}/projections/${projection_id}', {
            well_id: payload.wellId,
            projection_id: payload.projection_id,
        }),
    }),
    [REQUEST_PROJECTIONS_TREE]: async ({_}, payload) => apiCall({
        method: 'GET',
        url: await compileUrl('wells/${well_id}/projections_tree', {
            well_id: payload.wellId,
        }),
    }),
    [REQUEST_BACK_TO_PLAN]: async (_, {wellId}) => apiCall({
        method: 'GET',
        url: await compileUrl('wells/${well_id}/back_to_plan/current', {
            well_id: wellId,
        }),
    }),
    [FILE_UPLOAD]: ({_commit}, {url, method, data, onUploadProgress, requestId}) => {
        return apiCall({
            url,
            method: method || 'POST',
            data,
            headers: {'Content-Type': 'multipart/form-data'},
            onUploadProgress,
            requestId,
        })
    },
    [FILE_DOWNLOAD]: ({commit}, urlInfo = {}) => {
        return new Promise((resolve, reject) => {
            _apiCall({commit}, {
                method: urlInfo.method || 'POST',
                url: getUrl(urlInfo),
                data: urlInfo.data,
                params: urlInfo.params,
                headers: {
                    accept: 'application/octet-stream, application/json',
                    // 'Content-Type': 'application/json',
                },
                responseType: 'blob',
            })
                .then(resp => {
                    const name = getFileNameFromHeader(resp.request.getResponseHeader('Content-Disposition'))
                    let filename = unescape(name)
                    filename = filename.replace('%', '%25') // replace % symbol to allow URI decoding
                    const url = window.URL.createObjectURL(resp.data)
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', decodeURI(filename))
                    link.click()
                    resolve(resp)
                })
                .catch(reason => reject(reason))
        })
    },
    [WPS_CHART_REQUEST]: ({rootGetters}, payload) => {
        return new Promise((resolve, reject) => {
            const wellId = rootGetters.getSelectedWellId
            if (!wellId) {
                const err = {message: 'No well selected!'}
                console.error(err.message)
                resolve()
                return
            }

            const period = rootGetters.getActualPeriod
            const params = {
                start: period.start_depth,
                end: period.end_depth,
                ...payload.params,
            }

            apiCall({
                method: 'GET',
                url: `wps/${wellId}/${payload.chartName}`,
                params,
            })
                .then(resp => {
                    resolve(resp.data.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [REQUEST_AC_REPORT]: ({_commit}, payload) => apiCall({
        method: 'GET',
        url: `wells/${payload.wellId}/wps/ac_report/${payload.surveyId}`,
        params: payload.params,
    }).catch(err => {
        simpleErrorNotification(err.data.message)
    }),
    [REQUEST_AC_SUMMARY_REPORT]: ({_commit}, payload) => apiCall({
        method: 'POST',
        url: `wells/${payload.wellId}/wps/ac_report/summary`,
        params: payload.params,
        data: payload.data,
    }).catch(err => {
        simpleErrorNotification(err.data.message)
    }),
    [REQUEST_AC_RISK]: ({_commit}, payload) => apiCall({
        method: 'POST',
        url: `wells/${payload.wellId}/wps/ac_report/risk`,
        params: payload.params,
        data: payload.data,
    }).catch(err => {
        simpleErrorNotification(err.data.message)
    }),
    [REQUEST_AC_LOOKAHEAD_DESIGNS]: (ctx, payload) => apiCall({
        url: `wells/${payload.wellId}/wps/ac_report/lookahead_designs`,
    }).catch(err => {
        simpleErrorNotification(err.data.message)
    }),
    [REQUEST_REFERENCE_DESIGNS]: () => apiCall({
        url: `wells/${store.getters.getSelectedWellId}/wps/reference_designs`,
    }).catch(err => {
        simpleErrorNotification(err.data.message)
    }),
    [SEARCH_EDM_OFFSETS]: (ctx, data = {}) => apiCall({
        method: 'POST',
        url: `/wells/${data.wellId}/wps/edm_offsets/search`,
        data: data.payload || {}
    }),
    [SEARCH_WELL_PLANNING_EDM_OFFSETS]: (ctx, data = {}) => apiCall({
        method: 'POST',
        url: `${data.url}/search`,
        data: data.payload
    }),
    [REQUEST_REPORT]: (ctx, payload) => {
        return new Promise(() => {
            const what = {
                method: payload.method || 'GET',
                url: payload.url || 'export/${well_id}/${report_name}',
                well_id: payload.wellId,
                report_name: payload.reportName,
                params: {
                    type: payload.fileType,
                    ...payload.params,
                },
                data: payload.data,
            }
            store.dispatch(FILE_DOWNLOAD, what)
        })
    },
    [REQUEST_MW_REPORT]: (ctx, payload) => {
        return new Promise(() => {
            const what = {
                method: payload.method || 'POST',
                url: payload.url || 'export/mw_distribution',
                params: {
                    type: payload.fileType,
                    ...payload.params,
                },
                data: payload.data,
            }
            store.dispatch(FILE_DOWNLOAD, what)
        })
    },
    [REQUEST_TITLE_LIST]: () => apiCall({
        method: 'GET',
        url: `projects/nav_fields`,
    }),
    [SHRINK_AUDIT]: () => apiCall({
        url: 'admin/audit/shrink',
        method: 'GET',
    }),
    [RIGS_FOR_ALERT]: () => apiCall({
        url: 'rigs/accessible',
        method: 'GET',
    }),
    [REQUEST_TF]: (ctx, {params, wellId}) => apiCall({
        method: 'GET',
        url: `tf/${wellId}`,
        params,
    }),
    [REQUEST_MULTI_WELL_DATA]: (ctx, {data, requestId}) => apiCall({
        method: 'POST',
        url: `multi-well`,
        data,
        requestId,
    }),
    [REQUEST_FILTERED_MULTI_WELL_LIST]: (ctx, data) => apiCall({
        method: 'POST',
        url: `multi-well/search`,
        params: {well_stage: store.getters.getWellStage},
        data,
    }),
    [REQUEST_ACTIVE_WELLS_LIST]: () => apiCall({
        method: 'GET',
        url: `wells/search/active`,
        params: {well_stage: store.getters.getWellStage},
    }),
    [REQUEST_FILTERED_WELLS_LIST]: (ctx, data) => apiCall({
        method: 'POST',
        url: `wells/search`,
        params: {well_stage: store.getters.getWellStage},
        data,
    }),
    [REQUEST_FILTERED_OFFSET_LIST]: (_ctx, payload) => {
        const {wellId, ...data}  = payload
        return apiCall({
            method: 'POST',
            url: `wells/${wellId}/offset_search`,
            data,
        })
    },
    [REQUEST_FILTERED_WELLS_NUMBER]: (ctx, data) => apiCall({
        method: 'POST',
        url: `multi-well/search_count`,
        params: {well_stage: store.getters.getWellStage},
        data,
    }),
    [REQUEST_MULTI_WELL_DVD]: (ctx, {data, requestId}) => apiCall({
        method: 'POST',
        url: `multi-well/dvd`,
        data,
        requestId,
    }),
    [REQUEST_SINGLE_WELL_DVD]: (ctx, {data}) => apiCall({
        method: 'POST',
        url: `charts/days_vs_depth`,
        data,
    }),
    [REQUEST_COST_VS_DEPTH_DVD]: (ctx, {data, wellId}) => apiCall({
        method: 'POST',
        url: `wells/${wellId}/time_cost_estimate/days_vs_depth`,
        data,
    }),
    [REQUEST_COST_VS_DEPTH]: (ctx, {data}) => apiCall({
        method: 'POST',
        url: `charts/cost`,
        data,
    }),
    [RECALCULATE_PERIOD]: (ctx, payload) => apiCall({
        method: 'GET',
        url: `data/${store.getters.getSelectedWellId}/recalculate`,
        params: payload.params,
        data: payload.data,
    }),
    [RELOAD_PERIOD]: (ctx, payload) => _apiCall(ctx, {
        method: 'GET',
        url: `data/${store.getters.getSelectedWellId}/reload`,
        params: payload.params,
        data: payload.data,
        needSuccessNotification: true,
    }),
    [REQUEST_FAVORITES]: () => apiCall({
        method: 'GET',
        params: {well_stage: store.getters.getWellStage},
        url: `/favorites`,
    }),
    [CHANGE_FAVORITE_WELL]: (ctx, data) => apiCall({
        method: data.is_favorite ? 'DELETE' : 'POST',
        url: `/wells/${data.id}/favorite`,
    }),
    [CHANGE_FAVORITE_RIG]: (ctx, data) => apiCall({
        method: data.is_favorite ? 'DELETE' : 'POST',
        url: `/rigs/${data.rig_id}/favorite`,
    }),
    [REQUEST_RECENT_WELLS]: () => apiCall({
        method: 'GET',
        params: {well_stage: store.getters.getWellStage},
        url: `/wells/recent`,
    }),
    [REQUEST_ALL_RIGS]: (ctx, data) => apiCall({
        method: 'GET',
        params: {well_stage: data?.wellStage},
        url: `/rigs/well_rigs`,
    }),
    [REQUEST_RIG]: (ctx, id) => apiCall({
        method: 'GET',
        url: `/rigs/${id}/rig_info`,
    }),
    [RESET_WITSML]: (ctx, data) => apiCall({
        method: 'GET',
        url: `/trajectory_data_source/${data.wellId}/reset`,
    }),
    [RESET_CORRECT_SURVEYS]: () => apiCall({
        method: 'POST',
        url: `/wells/${store.getters.getSelectedWellId}/wps/reload_surveys_from_provider`,
    }),
    [REQUEST_SLIDESHEET]: ({_commit}, params) => {
        if (_.isEmpty(params.dataRequestParams)) {
            const err = {message: 'Empty request params!'}
            console.error(err.message)
            return
        }
        if (!params.dataRequestParams.well_id) {
            const err = {message: 'No well selected!'}
            console.error(err.message)
            return
        }

        // todo refactor to get method
        //TODO: remove "...payload" after back refactoring
        return apiCall({
            method: 'POST',
            url: params.url || `charts/slidesheet`,
            data: {...params.dataRequestParams, filter: params.dataRequestParams, ...params.data},
        })
    },
    [REQUEST_TIMELOG]: ({_commit}, params) => {
        let payload = {...store.getters.getDataRequestParams}
        if (_.isEmpty(payload)) {
            const err = {message: 'Empty request params!'}
            console.error(err.message)
            return
        }
        if (!payload.well_id) {
            const err = {message: 'No well selected!'}
            console.error(err.message)
            return
        }
        if (params.filteredByWell) {
            let selectedWell = store.getters.getSelectedWell
            payload.start_date = selectedWell.start_date
            payload.end_date = selectedWell.end_date
            payload.start_depth = selectedWell.start_depth
            payload.end_depth = selectedWell.end_depth
        }
        payload.typesFilter = params.typesFilter

        // todo refactor to get method
        //TODO: remove "...payload" after back refactoring
        return apiCall({
            method: 'POST',
            url: params.url || `charts/time_log`,
            data: {...payload, filter: payload, ...params.data},
        })
    },
    [REQUEST_MAP_SETTINGS]: ({_commit}, payload) => apiCall({
        method: 'GET',
        url: `geo/settings`,
        //params: payload.params,
        requestId: payload.requestId
    }),
    [REQUEST_HEATMAP_DATA]: ({_commit}, payload) => apiCall({
        method: 'POST',
        url: `geo/heatmap_data`,
        params: payload.params,
        data: payload.data,
        requestId: payload.requestId
    }),
    [REQUEST_HEATMAP_SMART_PARAMS]: ({_commit}, payload = {}) => apiCall({
        method: 'GET',
        url: `geo/smart_params`,
        params: {
            well_stage: store.getters.getWellStage,
            ...payload.params,
        },
        data: payload.data,
        requestId: payload.requestId
    }),
    [REQUEST_FEATURE_INFO]: ({_commit}, payload) => apiCall({
        method: 'POST',
        url: `geo/wfs/feature_info`,
        data: payload.data,
        params: payload.params,
        requestId: payload.requestId
    }),
    [REQUEST_AVAILABLE_WELL_FORMATIONS]: ({_commit}, {well_id}) => apiCall({
        method: 'GET',
        url: `references/formations`,
        params: {well_id},
    }),
    [REQUEST_READY_FOR_SHIPMENT_TOOLS]: (ctx, data) => {
        return _apiCall(ctx, {
            method: 'GET',
            url: `/wells/${store.getters.getSelectedWellId}/bha/shipment/tools${data?.filter ? '/' + data.filter : ''}`,
        })
    },
    [REQUEST_BIT_RUN_STATISTIC]: (ctx, {break_down_by_phase}) => {
        return _apiCall(ctx, {
            method: 'GET',
            url: `/brs/${store.getters.getSelectedWellId}`,
            params: {break_down_by_phase}
        })
    },
    [REQUEST_STATISTICS_LIST]: (ctx) => {
        return _apiCall(ctx, {
            method: 'GET',
            url: `/smart/mw`,
            params: {well_stage: store.getters.getWellStage},
        })
    },
    [REQUEST_STATISTICS_DATA]: (ctx, {sectionType, directionalType, params, data}) => {
        return _apiCall(ctx, {
            method: 'POST',
            url: `/smart/${store.getters.getSelectedWellId}/section/${sectionType}/directional/${directionalType}`,
            params,
            data,
        })
    },
    [REQUEST_COMPANY_USERS]: (ctx) => {
        return _apiCall(ctx, {
            method: 'GET',
            url: `/companies/users`,
        })
    },
    [REQUEST_SHARE_WIDGET]: (ctx, data) => {
        return _apiCall(ctx, {
            method: 'POST',
            url: `/backstore/fragment/share`,
            data,
            needSuccessNotification: true,
        })
    },
    [REQUEST_ALERT_INCIDENT]: (ctx, data) => apiCall({
        method: 'POST',
        url: `alerts/incident`,
        data,
    }),
    [REQUEST_ROTARY_TENDENCY]: (ctx, data) => apiCall({
        method: 'POST',
        url: `wps/buildwalk`,
        data,
    }),
    [REQUEST_FD_CATEGORIES_FOR_ALERTS]: () => apiCall({
        method: 'GET',
        url: `/file_drive/categories`,
    }),
    [REQUEST_RUNS_FOR_LOGS]: (ctx, {url, offsets, wellId}) => apiCall({
        url: `logs/${wellId}/${url}/runs`,
        method: 'POST',
        data: offsets || [],
    }).then(resp => resp.data),
    [REQUEST_RUN_DETAILS]: (ctx, {id, wellId}) => apiCall({
        url: `logs/${wellId}/timelog/run/${id}`,
        method: 'GET',
    }).then(resp => resp.data),
    [REQUEST_TRIPPING_DATA]: (ctx, {wellId, sectionType, requestId, params, data}) => {
        return _apiCall(ctx, {
            method: 'POST',
            url: `logs/${wellId}/section/${sectionType}`,
            params,
            data,
            requestId,
        })
    },
    [REQUEST_AXES_SCATTER_PLOT]: (ctx) => _apiCall(ctx, {
        method: 'POST',
        url: `well/${store.getters.getSelectedWellId}/crossdata/axes`,
    }),
    [REQUEST_COMMENTS_FOR_LOGS]: (ctx, payload) => _apiCall(ctx, {
        method: 'POST',
        url: `wells/${payload.wellId}/user_comments/list`,
        data: payload.data,
        params: payload.params
    }),
    [REQUEST_COMMENT_FOR_LOGS]: (ctx, payload) => _apiCall(ctx, {
        method: payload.method || 'GET',
        url: `wells/${payload.wellId || store.getters.getSelectedWellId}/user_comments/${payload.id}`,
        data: payload.data,
        params: payload.params,
        needSuccessNotification: true
    }),
    [CREATE_COMMENT_FOR_LOGS]: (ctx, payload) => _apiCall(ctx, {
        method: 'POST',
        url: `wells/${payload.wellId}/user_comments`,
        data: payload.data,
        params: payload.params,
        needSuccessNotification: true
    }),
    [REQUEST_RIG_MESSAGES_FOR_LOGS]: (ctx, payload) => _apiCall(ctx, {
        method: 'GET',
        url: `message/${payload.wellId}`,
        params: payload.params
    }),
    [REQUEST_RIG_MESSAGE_FOR_LOGS]: (ctx, payload) => _apiCall(ctx, {
        method: 'GET',
        url: `message/${payload.wellId}/${payload.id}`,
        params: payload.params
    }),
    [REQUEST_INCIDENTS_FOR_LOGS]: (ctx, payload) => _apiCall(ctx, {
        method: 'POST',
        url: `wells/${payload.wellId}/incident/list`,
        data: payload.data,
        params: payload.params
    }),
    [REQUEST_INCIDENT_FOR_LOGS]: (ctx, payload) => _apiCall(ctx, {
        method: payload.method || 'GET',
        url: `wells/${payload.wellId || store.getters.getSelectedWellId}/incident/${payload.id}`,
        data: payload.data,
        params: payload.params
    }),
    [SET_INCIDENT_FOR_LOGS]: (ctx, payload) => _apiCall(ctx, {
        method: payload.method || 'POST',
        url: `wells/${payload.wellId || store.getters.getSelectedWellId}/incident`,
        data: payload.data,
        params: payload.params,
        needSuccessNotification: true
    }),
    [REQUEST_DEPTH_BY_FIELD]: (ctx, payload) => _apiCall(ctx, {
        method: 'GET',
        url: `wells/${payload.wellId}/depth/by_${payload.field}`,
        params: payload.params
    }),
    [REQUEST_TECH_LIMIT_AGGREGATIONS]: (ctx) => _apiCall(ctx, {
        method: 'GET',
        url: `charts/days_vs_depth/tech_limit/aggregations`
    }),
    [REQUEST_RECOMMENDATIONS_FOR_LOGS]: (ctx, payload) => _apiCall(ctx, {
        method: 'POST',
        url: `wells/${payload.wellId}/recommendation/list`,
        data: payload.data,
        params: payload.params
    }),
    [REQUEST_RECOMMENDATION_FOR_LOGS]: (ctx, payload) => _apiCall(ctx, {
        method: payload.method || 'GET',
        url: `wells/${payload.wellId || store.getters.getSelectedWellId}/recommendation/${payload.id}`,
        data: payload.data,
        params: payload.params
    }),
    [SET_RECOMMENDATION_FOR_LOGS]: (ctx, payload) => _apiCall(ctx, {
        method: payload.method || 'POST',
        url: `wells/${payload.wellId || store.getters.getSelectedWellId}/recommendation`,
        data: payload.data,
        params: payload.params,
        needSuccessNotification: true
    }),
    [GET_SECTION_OPERATIONS]: async (ctx, {well_id, prog_id}) => _apiCall(ctx, {
        method: 'GET',
        url: await compileUrl('wells/${well_id}/drill_prog/${prog_id}/section_operations/design_sections', {well_id, prog_id}),
    }).then(resp => resp.data.data),
    [REQUEST_VARIABLES]: async (ctx, {well_id, prog_id}) => _apiCall(ctx, {
        method: 'GET',
        url: await compileUrl('wells/${well_id}/drill_prog/${prog_id}/var', {well_id, prog_id}),
    }).then(resp => resp.data.data.map(variable => ({
        ...variable,
        value: format(variable.value, variable.data_type),
    }))),
}

export default {
    actions,
    modules: {
        scenarios,
    },
}

