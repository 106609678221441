import Crud from '@/store/modules/crud.js'

const URL = 'wells/${well_id}/users'

const users = new Crud(URL)

users.getters = () => ({
    getUsers: state => state.data,
})

export default users.module()
