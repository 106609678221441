import {DirectiveOptions} from 'vue'
import {DirectiveBinding} from "vue/types/options"

function discardFocus(e: FocusEvent) {
    const target = e.target as HTMLElement
    target?.blur()
}

class FocusDirective implements DirectiveOptions {
    inserted = function (el: HTMLElement,
        binding: DirectiveBinding) {
        if (binding.modifiers.prevent) {
            el.addEventListener('focus', discardFocus)
        }
    }
    unbind = function (el: HTMLElement,
        binding: DirectiveBinding) {
        if (binding.modifiers.prevent) {
            el.removeEventListener('focus', discardFocus)
        }
    }
}

export default new FocusDirective()

