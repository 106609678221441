import Crud from '@/store/modules/crud.js'
import {toSelect} from "@/utils/helpers"

const URL = 'references/company_types'

const companyTypes = new Crud(URL)

companyTypes.getters = () => ({
    getCompanyTypesToSelect: state => toSelect(state.data, 'title'),
})

export default companyTypes.module()
