import {_apiCall as apiCall, apiCall as apiCall2} from "@/utils/api"
import store from "@/store"
import RequestCacheManager from "@/utils/LocalCache"
import tool_catalog from "@/store/modules/equipment/tool_catalog"
import rig_inventory from "@/store/modules/equipment/rig_inventory"

const createStorage = () => {
    return {
        equipmentTypes: {
            all: [],
            bha: [],
            casing: [],
        },
        metaDescription: {},
    }
}

const state = createStorage()

const getters = {
    getEquipmentTypesList: state => type => state.equipmentTypes[type],
    getEquipmentTypesListWithAll: state => type => [
        {key: undefined, value: 'All Items'},
        ...state.equipmentTypes[type]
    ],
    getEquipmentDescription: state => state.metaDescription,
}

const mutations = {
    STORE_EQUIPMENT_COMPONENTS_MODELS: (state, data) => {
        state.metaDescription = data.meta_description
        state.equipmentTypes.all = data.equipment_types_all
        state.equipmentTypes.bha = data.equipment_types_bha
        state.equipmentTypes.casing = data.equipment_types_casing
    },
}
const actions = {
    REQUEST_EQUIPMENT_WELLS: (ctx, {type, wellId}) => apiCall(ctx, {
        method: 'GET',
        url: `/${type}/wells/${wellId}`,
    }),
    REQUEST_EQUIPMENT: (ctx, payload) => {
        const wellId = (payload && payload.wellId) ?? store.getters.getSelectedWellId
        return apiCall(ctx, {
            method: 'GET',
            url: (payload && payload.id) ?
                `/wells/${wellId}/${payload.url}/${payload.id}/current_version` :
                `/wells/${wellId}/${payload.url}`,
        })
    },
    REQUEST_EQUIPMENT_COMPONENTS_MODELS: ({commit, state}) => {
        if (state.equipmentTypes.all.length) return
        return apiCall({commit}, {
            method: 'GET',
            url: `/bha/meta`,
        }).then(resp => commit('STORE_EQUIPMENT_COMPONENTS_MODELS', resp.data.data))
    },
    CANCEL_DRAFT_MODE: (ctx, data) => apiCall(ctx, {
        method: 'DELETE',
        url: `/wells/${data.wellId}/${data.type}/${data.id}/draft/${data.draftId}`,
    }),
    APPLY_DRAFT: (ctx, data) => apiCall(ctx, {
        method: 'PATCH',
        url: `/wells/${data.wellId}/${data.type}/${data.id}/draft/${data.draftId}/apply`,
    }),
    REQUEST_EQUIPMENT_DICTIONARY: (ctx, {dictionary, wellId}) => RequestCacheManager.cache(apiCall2, {
        method: 'GET',
        url: `/bha/dict/${dictionary}${wellId ? `/${wellId}` : ''}`,
    })
        .then(resp => {
            return resp.data.data
        }),
    REQUEST_EQUIPMENT_WELLS_FOR_INVENTORY_CLONE: (ctx, params = {}) => apiCall(ctx, {
        method: 'GET',
        url: `/bha/wells/inventory`,
        params,
    }),
    REQUEST_SHIPMENT_TOOLS: (ctx, wellId) => {
        return apiCall(ctx, {
            method: 'GET',
            url: `/wells/${wellId}/bha/shipment/tools`,
        })
    },
    CLONE_SHIPMENT_TOOLS: (ctx, data) => apiCall(ctx, {
        method: 'POST',
        url: `/wells/${store.getters.getSelectedWellId}/bha/shipment/copy`,
        data: data,
    }),
    ADD_TO_CATALOG_BY_ID: (ctx, {wellId, ids}) => apiCall(ctx, {
        method: 'POST',
        url: `/bha/catalog/add`,
        data: {
            well_id: wellId,
            ids,
        }
    }),
    ADD_TO_INVENTORY_BY_ID: (ctx, {wellId, ids}) => apiCall(ctx, {
        method: 'POST',
        url: `/wells/${wellId}/bha/inventory/add`,
        data: {
            ids,
        }
    }),
    REQUEST_EQUIPMENT_PROJECTS_FOR_CATALOG_CLONE: (ctx) => apiCall(ctx, {
        method: 'GET',
        url: `/bha/catalog/projects/list`,
    }),
    REQUEST_PROJECTS_SHIPMENT_TOOLS: (ctx, project_id) => {
        return apiCall(ctx, {
            method: 'GET',
            url: `/bha/catalog/tools`,
            params: {project_id},
        })
    },
    CLONE_PROJECTS_SHIPMENT_TOOLS: (ctx, {data, project_id}) => apiCall(ctx, {
        method: 'POST',
        url: `/bha/catalog/copy`,
        params: {project_id},
        data: data,
    }),
    MARK_EQUIPMENT_COMPONENT_AS_FAILED: (ctx, {well_id, bha_id, component_id}) => apiCall(ctx, {
        method: 'POST',
        url: `/wells/${well_id}/bha/${bha_id}/component/${component_id}/mark_failed`,
        data: {},
    }),
    UNMARK_EQUIPMENT_COMPONENT_AS_FAILED: (ctx, {well_id, bha_id, component_id}) => apiCall(ctx, {
        method: 'POST',
        url: `/wells/${well_id}/bha/${bha_id}/component/${component_id}/unmark_failed`,
        data: {},
    }),
}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules: {
        tool_catalog,
        rig_inventory,
    }
}
