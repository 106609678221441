import Crud from '@/store/modules/crud.js'

const URL = 'companies/${company_id}/employees'

const employees = new Crud(URL)

employees.getters = () => ({
    getUsers: state => state.data,
})


export default employees.module()
