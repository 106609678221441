import {_apiCall as apiCall} from "@/utils/api"

const getDefaultState = () => {
    return {
    }
}

const state = getDefaultState()

const getters = {
}

const actions = {
    REQUEST_INVENTORY_TOOLS: (ctx, data) => apiCall(ctx, {
        method: 'GET',
        url: `/wells/${data.wellId}/bha/inventory${data?.filter ? '/' + data.filter : ''}`,
        params: data.tool_status ? {tool_status: data.tool_status} : undefined
    }),
    CLONE_TO_CATALOG: (ctx, data) => apiCall(ctx, {
        method: 'POST',
        url: `/bha/catalog/add/${data.id}`,
    }),
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
