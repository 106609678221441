import {REQUEST_WELLS, REQUEST_OFFSET_WELLS, REQUEST_WELL} from "@/store/actions/params/wells"
import {apiCall} from "@/utils/api"

const actions = {
    // Запрос информации о скважине
    [REQUEST_WELL]: (_, {wellId, wellStage} ) => apiCall({
        method: 'get',
        url: `/wells/${wellId}`,
        params: {well_stage: wellStage},
        uninterrupted: true,
    }).then(resp => resp.data.data),
    // Запросить список доступных для пользователя скважин
    [REQUEST_WELLS]: (_) => {
        return new Promise((resolve, reject) => {
            const what = {
                method: 'get',
                url: '/wells',
                uninterrupted: true,
            }
            apiCall(what)
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    // Запрос списка оффсетных скважин для указанной референсной скважины
    [REQUEST_OFFSET_WELLS]: (_, refWellId) => {
        return new Promise((resolve, reject) => {
            const what = {
                method: 'get',
                url: `/wells/${refWellId}/offset_wells`
            }
            apiCall(what)
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}

export default {
    actions,
}
