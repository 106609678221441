import Crud from '@/store/modules/crud.js'
import {toSelect} from "@/utils/helpers"

const URL = 'projects/${project_id}/wells'

const wells = new Crud(URL)

wells.getters = () => ({
    getWells: state => state.data,
    getWellsToSelect: state => toSelect(state.data),
    getWellsWithLastRig: state => state.data.reduce((acc, well) => {
        const lastRig = _.maxBy(well.well_rigs, 'spud_date') || {}
        acc.push({...well, last_rig_name: lastRig.rig_name, last_rig_id: lastRig.rig_id})
        return acc
    }, []),
})

export default wells.module()
