export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_IS_ADMIN = 'AUTH_IS_ADMIN'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_GET_SSO_LINK = 'AUTH_GET_SSO_LINK'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_PASSWORD = 'AUTH_PASSWORD'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_LOGOUT_WITH_SSO = 'AUTH_LOGOUT_WITH_SSO'
export const REQUEST_WELL_PERMISSIONS = 'REQUEST_WELL_PERMISSIONS'
export const REQUEST_PROJECT_PERMISSIONS = 'REQUEST_PROJECT_PERMISSIONS'
export const REQUEST_AND_APPLY_SINGLE_WELL_PERMISSIONS = 'REQUEST_AND_APPLY_SINGLE_WELL_PERMISSIONS'
export const SET_WELL_PERMISSIONS = 'SET_WELL_PERMISSIONS'
export const SET_STATUS_WELL_PERMISSIONS = 'SET_STATUS_WELL_PERMISSIONS'
export const SET_LOGOUT_URL = 'SET_LOGOUT_URL'
export const USER_IMPERSONATE = 'USER_IMPERSONATE'
export const USER_DEIMPERSONATE = 'USER_DEIMPERSONATE'
