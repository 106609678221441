export const WPS_DATA_REQUEST = 'WPS_DATA_REQUEST'
export const WPS_SEPARATION_DATA_REQUEST = 'WPS_SEPARATION_DATA_REQUEST'
export const WPS_PROXIMITY_DATA_REQUEST = 'WPS_PROXIMITY_DATA_REQUEST'
// export const WPS_BUILDWALK_REQUEST = 'WPS_BUILDWALK_REQUEST'
export const WPS_DATA_SUCCESS = 'WPS_DATA_SUCCESS'
export const WPS_DATA_ERROR = 'WPS_DATA_ERROR'
export const WPS_STATE_RESET = 'WPS_STATE_RESET'

export const WPS_SURVEY = 'survey'
export const WPS_PLAN = 'plan'
export const WPS_PLAN_TRAJECTORIES = 'plan_trajectories'
export const WPS_BUILDWALK = 'buildwalk'
