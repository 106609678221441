export const SET_APP_STATE = 'SET_APP_STATE'
export const SAVE_APP_STATE = 'SAVE_APP_STATE'
export const LOAD_APP_STATE = 'LOAD_APP_STATE'
export const SET_PARAMETER = 'SET_PARAMETER'
export const SET_EDIT_MODE = 'SET_EDIT_MODE'
export const SET_DIRTY_DATA = 'SET_DIRTY_DATA'
export const SYNC_FILTER = 'SYNC_FILTER'
export const SYNC_URL_PARAMS = 'SYNC_URL_PARAMS'
export const SELECT_WELL = 'SELECT_WELL'
export const SELECT_PERIOD = 'SELECT_PERIOD'
export const REQUEST_PARAMS = 'REQUEST_PARAMS'
export const SET_SELECT_TEMPLATE = 'SET_SELECT_TEMPLATE'
export const SET_APP_PARAMS_STATUS = 'SET_APP_PARAMS_STATUS'
export const GET_SELECTED_WIDGET_ID = 'GET_SELECTED_WIDGET_ID'
export const SET_PORTRAIT_ORIENTATION = 'SET_PORTRAIT_ORIENTATION'
export const SET_SELECTED_WELL_DETAILS = 'SET_SELECTED_WELL_DETAILS'
export const WELL_UPDATE = 'WELL_UPDATE'
export const SET_LAST_UPDATED = 'SET_LAST_UPDATED'
export const SET_LAST_MULTI_WELL_PATH = 'SET_LAST_MULTI_WELL_PATH'
export const SET_LAST_ROUTE = 'SET_LAST_ROUTE'
export const ADD_LAYOUT_MODE = 'ADD_LAYOUT_MODE'
export const REMOVE_LAYOUT_MODE = 'REMOVE_LAYOUT_MODE'
export const SET_SELECT_WELL_ID = 'SET_SELECT_WELL_ID'
export const SET_NEXT_WELL_ID = 'SET_NEXT_WELL_ID'
