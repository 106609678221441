import Crud from '@/store/modules/crud.js'
import {toSelect} from "@/utils/helpers"

const URL = 'references/north_types'

const northTypes = new Crud(URL)

northTypes.getters = () => ({
    getWellNorthTypesToSelect: state => toSelect(state.data, 'title'),
})

export default northTypes.module()
