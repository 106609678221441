export interface AppConfig {
    VUE_APP_BASE_URL: string
    VUE_APP_BASE_API_URL: string
    VUE_APP_BASE_WS_URL: string
    VUE_APP_TITLE: string
    VUE_APP_STARTER_PICTURE: string
    VUE_APP_STARTER_PICTURE_TITLE: string
    VUE_APP_STARTER_PICTURE_SUBTITLE: string
    VUE_APP_STARTER_PICTURE_LOGO_STYLE: string
    VUE_APP_STARTER_PICTURE_SOCIAL_PANEL_STYLE: string
    VUE_APP_HIDE_SLIDE_SEEN_AND_MOTOR_YIELD_COLS_IN_SURVEY_TABLE: boolean
    VUE_APP_DEFAULT_MAP_CENTER: [number, number] | undefined
}
export const Config: Partial<AppConfig> = Object.freeze({
    VUE_APP_BASE_URL: window.appConfig?.VUE_APP_BASE_URL ?? process.env.VUE_APP_BASE_URL,
    VUE_APP_BASE_API_URL: window.appConfig?.VUE_APP_BASE_API_URL ?? process.env.VUE_APP_BASE_API_URL,
    VUE_APP_BASE_WS_URL: window.appConfig?.VUE_APP_BASE_WS_URL ?? process.env.VUE_APP_BASE_WS_URL,
    VUE_APP_TITLE: window.appConfig?.VUE_APP_TITLE ?? process.env.VUE_APP_TITLE,

    VUE_APP_STARTER_PICTURE: window.appConfig?.VUE_APP_STARTER_PICTURE ?? process.env.VUE_APP_STARTER_PICTURE,
    VUE_APP_STARTER_PICTURE_TITLE: window.appConfig?.VUE_APP_STARTER_PICTURE_TITLE ?? process.env.VUE_APP_STARTER_PICTURE_TITLE,
    VUE_APP_STARTER_PICTURE_SUBTITLE: window.appConfig?.VUE_APP_STARTER_PICTURE_SUBTITLE ?? process.env.VUE_APP_STARTER_PICTURE_SUBTITLE,
    VUE_APP_STARTER_PICTURE_LOGO_STYLE: window.appConfig?.VUE_APP_STARTER_PICTURE_LOGO_STYLE ?? process.env.VUE_APP_STARTER_PICTURE_LOGO_STYLE,
    VUE_APP_STARTER_PICTURE_SOCIAL_PANEL_STYLE: window.appConfig?.VUE_APP_STARTER_PICTURE_SOCIAL_PANEL_STYLE ?? process.env.VUE_APP_STARTER_PICTURE_SOCIAL_PANEL_STYLE,

    VUE_APP_HIDE_SLIDE_SEEN_AND_MOTOR_YIELD_COLS_IN_SURVEY_TABLE: (window.appConfig?.VUE_APP_HIDE_SLIDE_SEEN_AND_MOTOR_YIELD_COLS_IN_SURVEY_TABLE ?? process.env.VUE_APP_HIDE_SLIDE_SEEN_AND_MOTOR_YIELD_COLS_IN_SURVEY_TABLE) as boolean,
    VUE_APP_DEFAULT_MAP_CENTER: window.appConfig?.VUE_APP_DEFAULT_MAP_CENTER ?? (process.env.VUE_APP_DEFAULT_MAP_CENTER ? JSON.parse(process.env.VUE_APP_DEFAULT_MAP_CENTER) : undefined),
})
