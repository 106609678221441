import {
    AUTH_REQUEST,
    AUTH_IS_ADMIN,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_LOGOUT,
    AUTH_PASSWORD,
    REQUEST_WELL_PERMISSIONS,
    REQUEST_PROJECT_PERMISSIONS,
    REQUEST_AND_APPLY_SINGLE_WELL_PERMISSIONS,
    SET_WELL_PERMISSIONS,
    SET_STATUS_WELL_PERMISSIONS,
    SET_LOGOUT_URL,
    USER_IMPERSONATE,
    USER_DEIMPERSONATE,
    AUTH_GET_SSO_LINK,
    AUTH_LOGOUT_WITH_SSO,
} from '../actions/auth'
import {USER_STORE} from '../actions/user'
import {apiCall} from '@/utils/api'
import axios from "../../utils/axios"
import Vue, {nextTick} from 'vue'
import {PERMISSIONS} from "@/assets/enums.ts"
import {simpleErrorNotification} from "@/utils/helpers"

function getCookie(cookieName) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${cookieName}=`)

    if (parts.length === 2) return parts.pop().split(';').shift()
}
function removeCookie(cookieName) {
    document.cookie=`${cookieName}=; path=/; max-age=0`
}

function getCookedKeyValue(key) {
    const stored = localStorage.getItem(key)
    if (stored) {
        return stored
    }
    let cooked = getCookie(key)
    if (cooked) {
        cooked = decodeURIComponent(cooked)
        localStorage.setItem(key, cooked)
        removeCookie(key)
        return cooked
    }
    return ''
}

const state = {
    is_admin: localStorage.getItem('user.is_admin') === 'true' || false,
    token: getCookedKeyValue('user.token'),
    logout_url: getCookedKeyValue('user.logout_url'),
    previous_token: localStorage.getItem('user.previous_token') || '',
    status: '',
    response: {},
    roles: {permissions: {}, status: null},
}

if (state.token) {
    axios.defaults.headers.common['Authorization'] = state.token
    state.status = 'local'
}

const getters = {
    isAuthenticated: state => !!state.token,
    isAdmin: state => state.is_admin,
    isImpersonated: state => !!state.previous_token,
    isPermissionLoaded: state => state.roles.status === 'loaded',
    hasPermission: state => permission => {
        return Boolean(state.is_admin || state.roles.permissions[PERMISSIONS[permission]])
    },
    getPermissions: state => state.roles.permissions,
    getAuthToken: state => state.token,
}

const actions = {
    [USER_IMPERSONATE]: ({_commit}, item) => {
        apiCall({
            url: `auth/generate_login_as_user_token/` + item.id,
            method: 'POST'
        })
            .then(resp => {
                localStorage.setItem('user.previous_token', state.token)
                localStorage.setItem('user.previous_is_admin', state.is_admin)
                localStorage.setItem('user.token', resp.data.data.token)
                window.location.assign('/')
            })
            .catch(error => simpleErrorNotification(error.data.message))
    },
    [USER_DEIMPERSONATE]: ({_commit}) => {
        localStorage.setItem('user.token', localStorage.getItem('user.previous_token'))
        localStorage.setItem('user.is_admin', localStorage.getItem('user.previous_is_admin'))
        cleanPrevious()
        window.location.assign('/')
    },
    [REQUEST_WELL_PERMISSIONS]: ({_commit}, well_id) => {
        return apiCall({url: `auth/${well_id}/WELL/permissions`, method: 'GET'}).then(resp => resp.data.data.permissions)
    },
    [REQUEST_PROJECT_PERMISSIONS]: ({_commit}, project_id) => {
        return apiCall({url: `auth/${project_id}/PROJECT/permissions`, method: 'GET'}).then(resp => resp.data.data.permissions)
    },
    [REQUEST_AND_APPLY_SINGLE_WELL_PERMISSIONS]: ({commit, dispatch}, well_id) => {
        commit(SET_STATUS_WELL_PERMISSIONS, 'pending')
        return new Promise((resolve, reject) => {
            dispatch(REQUEST_WELL_PERMISSIONS, well_id)
                .then(permissions => {
                    commit(SET_WELL_PERMISSIONS, permissions)
                    resolve(permissions)
                    commit(SET_STATUS_WELL_PERMISSIONS, 'loaded')
                })
                .catch(err => {
                    reject(err)
                    commit(SET_STATUS_WELL_PERMISSIONS, 'error')
                })
        })
    },
    [AUTH_PASSWORD]: (context, data) => {
        return new Promise((resolve, reject) => {
            apiCall({url: 'auth/password/' + data.action, method: 'POST', data: data.payload})
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [AUTH_GET_SSO_LINK]: (context, data) => {
        return new Promise((resolve, reject) => {
            apiCall({url: 'auth/sso', method: 'POST', data: data.payload})
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [AUTH_REQUEST]: ({commit, dispatch}, user) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST)
            apiCall({url: 'auth/login', method: 'POST', data: user})
                .then(resp => {
                    commit(AUTH_SUCCESS, resp)
                    dispatch(USER_STORE, resp.data)
                    commit(AUTH_IS_ADMIN, resp.data.data.is_admin)
                    resolve(resp)
                })
                .catch(err => {
                    commit(AUTH_ERROR, err)
                    localStorage.removeItem('user.token')
                    reject(err)
                })
                .finally(() => cleanPrevious())
        })
    },
    [AUTH_LOGOUT_WITH_SSO]: ({commit, state}) => {
        commit(AUTH_LOGOUT)
        const ssoLogoutUrl = state.logout_url || localStorage.getItem('user.logout_url')
        if (ssoLogoutUrl) {
            localStorage.removeItem('user.logout_url')
            commit(SET_LOGOUT_URL, '')
            nextTick(() => {
                window.open(ssoLogoutUrl, '_self')
            })
        }
    }
}

const mutations = {
    [SET_LOGOUT_URL]: (state, url) => {
        Vue.set(state, 'logout_url', url)
    },
    [SET_STATUS_WELL_PERMISSIONS]: (state, status) => {
        Vue.set(state.roles, 'status', status)
    },
    [SET_WELL_PERMISSIONS]: (state, permissions) => {
        let allPermission = {}
        permissions.forEach(value => allPermission[value] = true)
        Vue.set(state.roles, 'permissions', allPermission)
    },
    [AUTH_REQUEST]: (state) => {
        state.status = 'authenticating'
    },
    [AUTH_IS_ADMIN]: (state, is_admin) => {
        state.is_admin = is_admin
        localStorage.setItem('user.is_admin', is_admin)
    },
    [AUTH_SUCCESS]: (state, resp) => {
        state.status = 'success'
        state.token = resp.data.token
        state.previous_token = ''
        localStorage.setItem('user.token', resp.data.token)
        axios.defaults.headers.common['Authorization'] = resp.data.token
        Vue.set(state, 'response', _.pick(resp, ['status', 'message']))
    },
    [AUTH_ERROR]: (state, err) => {
        state.status = 'error'
        Vue.set(state, 'response', err)
    },
    [AUTH_LOGOUT]: (state) => {
        state.token = ''
        state.previous_token = ''
        axios.defaults.headers.common['Authorization'] = ''
        state.is_admin = false
        localStorage.removeItem('user.token')
        localStorage.removeItem('user.is_admin')
        cleanPrevious()
    },
}

function cleanPrevious() {
    localStorage.removeItem('user.previous_token')
    localStorage.removeItem('user.previous_is_admin')
}

export default {
    state,
    getters,
    actions,
    mutations,
}
