import {
    USER_STORE,
    USER_REQUEST,
    USER_ERROR,
    USER_SUCCESS,
    USER_SET_PARAM,
    USER_SET_THEME_BEFORE_PRINT
} from '../actions/user'
import {apiCall} from '@/utils/api'
import Vue from 'vue'
import router from '@/router'
import {AUTH_IS_ADMIN, AUTH_LOGOUT} from '../actions/auth'

const state = {
    status: '',
    profile: {},
    locale: {},
    spatial: {base_maps_allowed: [], layers_allowed: []},
    themeBeforePrint: localStorage.getItem('user.theme_before_print') || 'black',
    theme: localStorage.getItem('user.theme') || 'black',
    skin: localStorage.getItem('user.skin') || 'default',
    footer: localStorage.getItem('user.footer') !== null ? JSON.parse(localStorage.getItem('user.footer')) : true,
    chat: localStorage.getItem('user.chat') !== null ? JSON.parse(localStorage.getItem('user.chat')) : true,
}

const getters = {
    getProfile: state => state.profile,
    getServerAllowedLayers: state => state.spatial,
    // getServerAllowedLayers: state => ({...state.spatial, base_maps_allowed: state.spatial.base_maps_allowed.filter(item => item !== 'Hybrid')}),
    isMultiWellEnabled: state => (state.profile.is_admin || state.profile.multi_well_enabled),
    isTestCaseEnabled: state => (state.profile.is_admin && state.profile.test_case_enabled),
    getUserTheme: state => state.theme,
    getThemeBeforePrint: state => state.themeBeforePrint,
    getUserSkin: state => state.skin,
    getUserLocale: state => state.locale,
    userStatus: state => state.status,
    hasFooter: state => state.footer,
    hasChat: state => state.chat
}

const actions = {
    [USER_SET_PARAM]: ({commit}, payload) => {
        commit(USER_SET_PARAM, payload)
    },
    [USER_STORE]: ({commit}, user) => {
        commit(USER_SUCCESS, user)
    },
    [USER_REQUEST]: ({commit}) => {
        commit(USER_REQUEST)
        apiCall({url: 'auth/user', uninterrupted: true})
            .then(resp => {
                commit(USER_SUCCESS, resp.data)
                commit(AUTH_IS_ADMIN, state.profile.is_admin)
            })
            .catch(_err => {
                commit(USER_ERROR)
                // if resp is unauthorized, logout, to
                commit(AUTH_LOGOUT)
            })
    },
}

const mutations = {
    [USER_SET_PARAM]: (state, payload) => {
        state[payload.key] = payload.value
        localStorage.setItem(`user.${payload.key}`, payload.value)
    },
    [USER_SET_THEME_BEFORE_PRINT]: (state, theme) => {
        state.themeBeforePrint = theme
        localStorage.setItem('user.theme_before_print', theme)
    },
    [USER_REQUEST]: (state) => {
        state.status = 'loading'
    },
    [USER_SUCCESS]: (state, user) => {
        state.status = 'success'
        Vue.set(state, 'profile', user.data)
        Vue.set(state, 'locale', user.locale)
        Vue.set(state, 'spatial', user.spatial)

        const flagRecords = router.app.$route.matched.filter(record => record.meta.hasProfileFlag)
        if (flagRecords && flagRecords.length && !state.profile.is_admin && !flagRecords.every(item => state.profile[item.meta.hasProfileFlag])) {
            const redirect = flagRecords.reverse().find(r => r.meta && r.meta.hasProfileFlagRedirect)
            if (redirect) {
                router.push({name: redirect.meta.hasProfileFlagRedirect})
                return
            }
            router.push({name: '403'})
        }
    },
    [USER_ERROR]: (state) => {
        state.status = 'error'
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
