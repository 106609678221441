import axios from 'axios'
import {Config} from '@/utils/config'

const baseDomain = Config.VUE_APP_BASE_API_URL
const baseUri = process.env.VUE_APP_API_URI

export const baseURL = `${baseDomain}${baseUri}/`

export default axios.create({
    baseURL: baseURL,
    headers: {
        // 'Authorization': `Bearer ${store.auth.token}`,
    },
})
