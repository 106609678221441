import Crud from '@/store/modules/crud.js'

const URL = 'references/states'

const states = new Crud(URL)

states.getters = () => ({
    getStatesToSelect: state => state.data,
})

export default states.module()
