import Crud from '@/store/modules/crud.js'

const URL = 'frac_fleets'

const frac_fleets = new Crud(URL)

frac_fleets.getters = () => ({
    getFracFleets: state => state.data,
    getFracFleetsByContractorId: state => contractorId => state.data.filter(item => item.contractor_id === contractorId),
})

export default frac_fleets.module()
