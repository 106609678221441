import {Vue} from "vue-property-decorator"

export enum KeyList {
    _1 = '49',
    _2 = '50',
    _3 = '51',
    _4 = '52',
    A = '65',
    S = '83',
    X = '88',
    Escape = '27',
    Delete = '46',
    Enter = '13'
}

export enum ModList {
    altKey = 'altKey',
    ctrlKey = 'ctrlKey',
    shiftKey = 'shiftKey'
}

export default class HotkeyManager {
    private listeners: Record<string, Function[]> = {};
    private vm: any;

    static install(vue: typeof Vue, vm?: any) {
        vue.prototype.$hotkeyManager = new HotkeyManager(vm)
    }

    constructor(vm: any) {
        this.vm = vm

        document.addEventListener('keydown', (e) => this._keyHandler(e))
    }

    private _keyHandler(e: any) {
        const key = e.keyCode as KeyList

        const modeKeys = []
        for (const modeKey of Object.values(ModList)) {
            if (e[modeKey]) {
                modeKeys.push(modeKey)
            }
        }

        const listenerKey = this.createListenerKey(key, modeKeys)

        if (!this.listeners[listenerKey]) {
            return
        }

        e.preventDefault()

        for (let i = 0; i < this.listeners[listenerKey].length; i++) {
            this.listeners[listenerKey][i](e)
        }
    }

    private createListenerKey (key: string, modeKeys?: ModList[] ) {
        let listenerKey = key
        if (modeKeys && modeKeys.length) {
            for (const modeKey of Object.values(ModList)) {
                if (modeKeys.includes(modeKey)) {
                    listenerKey += `_${modeKey}`
                }
            }
        }
        return listenerKey
    }

    addListener(key: string, callback: () => void, modeKeys?: ModList[]) {
        const listenerKey = this.createListenerKey(key, modeKeys)

        if (!this.listeners[listenerKey]) {
            this.listeners[listenerKey] = []
        }

        this.listeners[listenerKey].push(callback)
        return callback
    }
    removeListener(key: KeyList, callback: Function, modeKeys?: ModList[]) {
        const listenerKey = this.createListenerKey(key, modeKeys)
        const index = this.listeners[listenerKey].indexOf(callback)
        if (index === -1) {
            console.warn('Dont find hotkey callback: ', key)
            return
        }

        this.listeners[listenerKey].splice(index, 1)

        if (!this.listeners[listenerKey].length) {
            delete this.listeners[listenerKey]
        }
    }
}
