import Crud from '@/store/modules/crud.js'

const URL = 'wells/${well_id}/location'

const location = new Crud(URL)

const actions = location.actions()

location.getters = () => ({
    getLocation: state => state.data.data,
    getLocationHeaders: state => state.data.headers,
})

location.actions = () => ({
    ...actions,
    getGeoSystems: ({commit}, params) => {
        return location._apiCall({commit}, {url: 'references/geo_systems', params, skipCommit: true})
    },
})

export default location.module()
