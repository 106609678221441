import {Module} from "vuex"
import {apiCall} from "@/utils/api"
import FiltersList from "@/models/FilterList"
import {MULTI_WELL_AUTO_UPDATE_TYPE} from "@/assets/enums"
import {Nullable} from "@/types/common"
import {simpleErrorNotification} from "@/utils/helpers"
import {UUID} from "@/core/types/ai-types"
import store from '@/store'

export interface ISortItem {
    icon: string
    id: number
    label: string
    ascending: boolean
    by_value: boolean
    fields: Array<string>
}
export interface IDimensionOptionItem {
    name: string
    title: string
    color?: string
    shift_lightness: Nullable<number>
}
export interface AttributeItem {
    name: string
    default_value?: unknown
    default_unit?: UUID
    units: Array<{id: UUID}>
}
export interface IDimension {
    attrs: Array<AttributeItem>
    // id: number
    name: string
    title: string
    short_title: string
    sorts: Array<ISortItem>
    opts: Array<IDimensionOptionItem>
    color?: string
    shift_lightness?: number

}
export interface GeneralWidgetConfig {
    filter: FiltersList
    filterData: Array<any>
}

export interface MultiWellParamsState {
    autoUpdateType: string,
    generalWidgetConfig: GeneralWidgetConfig
}

export interface RootState {
}



const defaultState = (): MultiWellParamsState => ({
    autoUpdateType: localStorage.getItem('multiWell.autoUpdateType') || MULTI_WELL_AUTO_UPDATE_TYPE.NONE.value,
    generalWidgetConfig: {
        filter: new FiltersList(),
        filterData: [],
    }
})

const multiWell: Module<MultiWellParamsState, RootState> = {
    namespaced: true,

    state: defaultState(),

    getters: {
        getGeneralConfig: state => state.generalWidgetConfig,
        getAutoUpdateType: state => state.autoUpdateType
    },

    mutations: {
        SET_GENERAL_WIDGET_CONFIG: (state, payload = {}) => {
            Object.assign(state.generalWidgetConfig, payload)
        },
        SET_AUTO_UPDATE_TYPE: (state, payload = '') => {
            state.autoUpdateType = payload
            localStorage.setItem('multiWell.autoUpdateType', payload)
        }
    },

    actions: {
        REQUEST_DIMENSIONS: () => apiCall({
            method: 'GET',
            url: `multi-well/tree`,
            params: {
                well_stage: store.getters.getWellStage || undefined,
            }
        }).then((resp: {data: unknown}) => resp.data),
        REQUEST_DISTRIBUTION_REPORT_LIST: (_state, url: string) => apiCall({method: 'GET', url})
            .then((resp: {data: {data: unknown}}) => resp.data.data),
        // REQUEST_RUN_DISTRIBUTION_LEGENDS_LIST: () => apiCall({method: 'GET', url: `multi-well/run_distribution/legend`})
        //     .then((resp: {data: {data: unknown}}) => resp.data.data),
        // REQUEST_RUN_DISTRIBUTION_STATISTICS_LIST: () => apiCall({method: 'GET', url: `multi-well/run_distribution/statistics`})
        //     .then((resp: {data: {data: unknown}}) => resp.data.data),
        REQUEST_DISTRIBUTION_STATISTICS_DATA: (_state, {params, data, url}) => apiCall({method: 'POST', url, params, data})
            .then((resp: {data: {data: unknown}}) => resp.data),
        // REQUEST_RUN_DISTRIBUTION_STATISTICS_DATA: (_state, {params, data}) => apiCall({method: 'POST', url: `multi-well/run_distribution`, params, data})
        //     .then((resp: {data: {data: unknown}}) => resp.data),
        REQUEST_CUSTOM_DISTRIBUTION_DATA: (_state, {params, data, requestId}) => apiCall({method: 'POST', url: `multi-well/custom_distribution`, params, data, requestId})
            .then((resp: {data: {data: unknown}}) => resp.data)
            .catch(err => {
                simpleErrorNotification(err.message)
                return Promise.reject(err)
            })
    }
}

export default multiWell
