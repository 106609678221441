import {formatNumber} from "@/utils/format"

const isEmpty = value => {
    return !value && value !== 0
}

const inRange = (min, max, value, params = {leftOpen: false, rightOpen: false}) => {
    if (isEmpty(min) && isEmpty(max)) {
        return true
    }
    const lowerOrEqual = (a, b) => {
        return a <= b
    }
    const lowerNotEqual = (a, b) => {
        return a < b
    }
    const greaterOrEqual = (a, b) => {
        return a >= b
    }
    const greaterNotEqual = (a, b) => {
        return a > b
    }
    const lower = params.leftOpen ? lowerOrEqual : lowerNotEqual
    const greater = params.rightOpen ? greaterOrEqual : greaterNotEqual
    if ((!max && max !== 0) && lower(value, min)) {
        return `Should be greater than ${formatNumber(min)}`
    } else if ((!min && min !== 0) && greater(value, max)) {
        return `Should be lower than ${formatNumber(max)}`
    } else if ((lower(value, min) || greater(value, max)) && (min != null && max != null)) {
        return `Should be between ${formatNumber(min)} and ${formatNumber(max)}`
    } else return true
}

export const rules = {
    required: value => !!value || value === 0 || 'Required field',
    notEmpty: value => !!(value && value.trim()) || value === 0 || 'This field could not be empty',
    isEmpty: value => !value || (typeof value === 'string' ? !value.trim() : false),
    isFilename: value => {
        const pattern = /^[^*\\/:?"<>|]+$/
        return pattern.test(value) || "Сan't contain any of the following characters: \\ / : * ? \" < > |"
    },
    isRequiredAndNumber: value => {
        const required = rules.required(value)
        if (required !== true) return required
        return rules.isNumber(value)
    },
    isNumber: value => {
        // const pattern = /^-?((\d+[.,]?\d*)|(\d*[.,]?\d+))$/;
        // const pattern = /^[+-]?(0|[.,]\d+|[1-9]\d*|[1-9]\d*[.,]\d*|0[.,]\d*)$/;
        const pattern = /^[+-]?(0|[.]\d+|[1-9]\d*|[1-9]\d*[.]\d*|0[.]\d*)$/
        return value === undefined || value === null || value === '' || pattern.test(value) || 'Should be a number'
    },
    isPositive: value => value == null || value > 0,
    isPositiveInteger: value => {
        const pattern = /^[1-9][0-9]*$/
        return value === undefined || value === null || value === '' || pattern.test(value) || 'Should be a integer and positive'
    },
    inRange,
    inRightOpenRange: (min, max, value) => inRange(min, max, value, {rightOpen: true}),
    inLeftOpenRange: (min, max, value) => inRange(min, max, value, {leftOpen: true}),
    inRangeOrEmpty: (min, max, value) => {
        const inRangeResult =  inRange(min, max, value)
        return (typeof inRangeResult !== 'string' && inRangeResult) || isEmpty(value) || `Should be between ${formatNumber(min)} and ${formatNumber(max)}`
    },
    maxLength: (max, value) => {
        if (value && value.length > max) {
            return `Length should be lower ${formatNumber(max)}`
        } else return true
    },
    /** nullable interegr/fraction version
        works, but not tested
    
    // digitCapacity: (integer, fraction, value) => { //check "integer.fraction" digit capacity
    //     if (value == null) {
    //         return true
    //     }
    //     const intRegexp = integer == null ? '*' : `{0,${integer}}`
    //     const fracRegexp = fraction == null ? '*' : `{0,${fraction}}`
    //     const pattern = new RegExp(`^-?\\d${intRegexp}(?:[.,]\\d${fracRegexp})?$`)
    //     return pattern.test(value) || 'Invalid value'
    // }, */
    digitCapacity: (integer, fraction, value) => { //check "integer.fraction" digit capacity
        const pattern = new RegExp(`^-?\\d{0,${integer}}(?:[.,]\\d{0,${fraction}})?$`)
        return value === null || value === undefined || pattern.test(value) || 'Invalid value'
    },
    customRegexp(message, pattern, value) {
        const regexp = new RegExp(pattern)
        return regexp.test(value) || message
    },
    min: value => (!value || value.length >= 8) || 'Min 8 characters',
    email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return !value || pattern.test(value) || 'E-mail is invalid'
    },
    phone: value => {
        const pattern = /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g
        return !value || pattern.test(value) || 'Phone number is invalid'
    },
    url: value => {
        const pattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/
        return !value || pattern.test(value) || 'URL is invalid'
    },
    kopEoc: ({min, max, key, data}, value) => {
        const v = parseFloat(value)
        const v2 = key === 'kop' ? parseFloat(data.eoc) : parseFloat(data.kop)

        if (!isNaN(v) && (v < parseFloat(min) || v > parseFloat(max))) {
            return `Should be between ${min} and ${max}`
        } else if (!isNaN(v2) && (key === 'kop') && v > v2) {
            return `Should be less than ${v2}`
        } else if (!isNaN(v2) && (key === 'eoc') && v < v2) {
            return `Should be greater than ${v2}`
        }

        return true
    }
}
