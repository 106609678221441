import Crud from '@/store/modules/crud.js'
import {rolesToSelect} from "@/utils/helpers"

const URL = 'admin/roles'

const roles = new Crud(URL)

roles.getters = () => ({
    getRoles: state => state.data,
    getRolesToSelect: state => rolesToSelect(state.data),
})

export default roles.module()
