export const REQUEST_PLAN_POINTS = 'REQUEST_PLAN_POINTS'
export const REQUEST_FACT_POINTS = 'REQUEST_FACT_POINTS'
export const REQUEST_INTERPRETATION_POINTS = 'REQUEST_INTERPRETATION_POINTS'
export const REQUEST_TARGET_WINDOWS_POINTS = 'REQUEST_TARGET_WINDOWS_POINTS'
export const REQUEST_TARGET_LINE_POINTS = 'REQUEST_TARGET_LINE_POINTS'
export const REQUEST_BACK_TO_PLAN_POINTS = 'REQUEST_BACK_TO_PLAN_POINTS'
export const REQUEST_PROJECTION = 'REQUEST_PROJECTION'
export const REQUEST_PROJECTIONS_POINTS = 'REQUEST_PROJECTIONS_POINTS'
export const REQUEST_PROJECTIONS = 'REQUEST_PROJECTIONS'
export const REQUEST_PROJECTIONS_TREE = 'REQUEST_PROJECTIONS_TREE'
export const REQUEST_BACK_TO_PLAN = 'REQUEST_BACK_TO_PLAN'
export const FILE_UPLOAD = 'FILE_UPLOAD'
export const FILE_DOWNLOAD = 'FILE_DOWNLOAD'
export const WPS_CHART_REQUEST = 'WPS_CHART_REQUEST'
export const REQUEST_AC_REPORT = 'REQUEST_AC_REPORT'
export const REQUEST_AC_SUMMARY_REPORT = 'REQUEST_AC_SUMMARY_REPORT'
export const REQUEST_AC_RISK = 'REQUEST_AC_RISK'
export const REQUEST_AC_LOOKAHEAD_DESIGNS = 'REQUEST_AC_LOOKAHEAD_DESIGNS'
export const REQUEST_REFERENCE_DESIGNS = 'REQUEST_REFERENCE_DESIGNS'
export const SEARCH_EDM_OFFSETS = 'SEARCH_EDM_OFFSETS'
export const SEARCH_WELL_PLANNING_EDM_OFFSETS = 'SEARCH_WELL_PLANNING_EDM_OFFSETS'
export const REQUEST_REPORT = 'REQUEST_REPORT'
export const REQUEST_MW_REPORT = 'REQUEST_MW_REPORT'
export const REQUEST_TITLE_LIST = 'REQUEST_TITLE_LIST'
export const SHRINK_AUDIT = 'SHRINK_AUDIT'
export const RIGS_FOR_ALERT = 'RIGS_FOR_ALERT'
export const REQUEST_TF = 'REQUEST_TF'
export const REQUEST_MULTI_WELL_DATA = 'REQUEST_MULTI_WELL_DATA'
export const RECALCULATE_PERIOD = 'RECALCULATE_PERIOD'
export const RELOAD_PERIOD = 'RELOAD_PERIOD'
export const REQUEST_FILTERED_WELLS_LIST = 'REQUEST_FILTERED_WELLS_LIST'
export const REQUEST_FILTERED_OFFSET_LIST = 'REQUEST_FILTERED_OFFSET_LIST'
export const REQUEST_ACTIVE_WELLS_LIST = 'REQUEST_ACTIVE_WELLS_LIST'
export const REQUEST_FILTERED_MULTI_WELL_LIST = 'REQUEST_FILTERED_MULTI_WELL_LIST'
export const REQUEST_FILTERED_WELLS_NUMBER = 'REQUEST_FILTERED_WELLS_NUMBER'
export const REQUEST_MULTI_WELL_DVD = 'REQUEST_MULTI_WELL_DVD'
export const REQUEST_SINGLE_WELL_DVD = 'REQUEST_SINGLE_WELL_DVD'
export const REQUEST_FAVORITES = 'REQUEST_FAVORITES'
export const CHANGE_FAVORITE_WELL = 'CHANGE_FAVORITE_WELL'
export const CHANGE_FAVORITE_RIG = 'CHANGE_FAVORITE_RIG'
export const REQUEST_RECENT_WELLS = 'REQUEST_RECENT_WELLS'
export const REQUEST_ALL_RIGS = 'REQUEST_ALL_RIGS'
export const RESET_WITSML = 'RESET_WITSML'
export const RESET_CORRECT_SURVEYS = 'RESET_CORRECT_SURVEYS'
export const REQUEST_SLIDESHEET = 'REQUEST_SLIDESHEET'
export const REQUEST_TIMELOG = 'REQUEST_TIMELOG'
export const REQUEST_FEATURE_INFO = 'REQUEST_FEATURE_INFO'
export const REQUEST_MAP_SETTINGS = 'REQUEST_MAP_SETTINGS'
export const REQUEST_HEATMAP_DATA = 'REQUEST_HEATMAP_DATA'
export const REQUEST_HEATMAP_SMART_PARAMS = 'REQUEST_HEATMAP_SMART_PARAMS'
export const REQUEST_AVAILABLE_WELL_FORMATIONS = 'REQUEST_AVAILABLE_WELL_FORMATIONS'
export const REQUEST_READY_FOR_SHIPMENT_TOOLS = 'REQUEST_READY_FOR_SHIPMENT_TOOLS'
export const REQUEST_BIT_RUN_STATISTIC = 'REQUEST_BIT_RUN_STATISTIC'
export const REQUEST_STATISTICS_LIST = 'REQUEST_STATISTICS_LIST'
export const REQUEST_STATISTICS_DATA = 'REQUEST_STATISTICS_DATA'
export const REQUEST_COMPANY_USERS = 'REQUEST_COMPANY_USERS'
export const REQUEST_SHARE_WIDGET = 'REQUEST_SHARE_WIDGET'
export const REQUEST_ALERT_INCIDENT = 'REQUEST_ALERT_INCIDENT'
export const REQUEST_ROTARY_TENDENCY = 'REQUEST_ROTARY_TENDENCY'
export const REQUEST_FD_CATEGORIES_FOR_ALERTS = 'REQUEST_FD_CATEGORIES_FOR_ALERTS'
export const REQUEST_RUNS_FOR_LOGS = 'REQUEST_RUNS_FOR_LOGS'
export const REQUEST_RUN_DETAILS = 'REQUEST_RUN_DETAILS'
export const REQUEST_TRIPPING_DATA = 'REQUEST_TRIPPING_DATA'
export const REQUEST_AXES_SCATTER_PLOT = 'REQUEST_AXES_SCATTER_PLOT'
export const REQUEST_COMMENTS_FOR_LOGS = 'REQUEST_COMMENTS_FOR_LOGS'
export const REQUEST_COMMENT_FOR_LOGS = 'REQUEST_COMMENT_FOR_LOGS'
export const CREATE_COMMENT_FOR_LOGS = 'CREATE_COMMENT_FOR_LOGS'
export const REQUEST_PROTOTYPES_POINTS = 'REQUEST_PROTOTYPES_POINTS'
export const REQUEST_RIG_MESSAGES_FOR_LOGS = 'REQUEST_RIG_MESSAGES_FOR_LOGS'
export const REQUEST_RIG_MESSAGE_FOR_LOGS = 'REQUEST_RIG_MESSAGE_FOR_LOGS'
export const REQUEST_INCIDENTS_FOR_LOGS = 'REQUEST_INCIDENTS_FOR_LOGS'
export const REQUEST_INCIDENT_FOR_LOGS = 'REQUEST_INCIDENT_FOR_LOGS'
export const SET_INCIDENT_FOR_LOGS = 'SET_INCIDENT_FOR_LOGS'
export const REQUEST_DEPTH_BY_FIELD = 'REQUEST_DEPTH_BY_FIELD'
export const REQUEST_TECH_LIMIT_AGGREGATIONS = 'REQUEST_TECH_LIMIT_AGGREGATIONS'
export const REQUEST_RECOMMENDATIONS_FOR_LOGS = 'REQUEST_RECOMMENDATIONS_FOR_LOGS'
export const REQUEST_RECOMMENDATION_FOR_LOGS = 'REQUEST_RECOMMENDATION_FOR_LOGS'
export const SET_RECOMMENDATION_FOR_LOGS = 'SET_RECOMMENDATION_FOR_LOGS'
export const GET_SECTION_OPERATIONS = 'GET_SECTION_OPERATIONS'
export const REQUEST_VARIABLES ='REQUEST_VARIABLES'
export const REQUEST_COST_VS_DEPTH = 'REQUEST_COST_VS_DEPTH'
export const REQUEST_COST_VS_DEPTH_DVD = 'REQUEST_COST_VS_DEPTH_DVD'
export const REQUEST_RIG = 'REQUEST_RIG'
