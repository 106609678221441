import users from './users'
import groups from './groups'

export default {
    namespaced: true,
    modules: {
        users,
        groups,
    }
}
