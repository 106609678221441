import {_apiCall} from "@/utils/api"
import {compileUrl} from "@/utils/functions.ts"
import {groupedList} from "@/utils/helpers"

function draftRequest(action) {
    const url = 'wells/${well_id}/engineering/scenarios/${scenario_id}/'+ action
    return async (ctx, {scenario_id, sessionId, well_id}) => _apiCall(ctx, {
        method: 'POST',
        url: await compileUrl(url, {
            well_id,
            scenario_id,
        }),
        sessionId,
    }).then(resp => resp.data.data)
}
const defaultParams = {
    needSuccessNotification: true
}
const actions = {
    REQUEST_SCENARIO: async (ctx, {scenario_id, well_id}) => _apiCall(ctx, {
        method: 'GET',
        url: await compileUrl('wells/${well_id}/engineering/scenarios/${scenario_id}', {
            well_id,
            scenario_id,
        }),
    }).then(resp => resp.data),
    UPDATE_SCENARIO: async (ctx, {scenario_id, data, well_id}) => _apiCall(ctx, {
        method: 'PATCH',
        url: await compileUrl('wells/${well_id}/engineering/scenarios/${scenario_id}', {
            well_id,
            scenario_id,
        }),
        data,
    }).then(resp => resp.data.data),
    UPDATE_SCENARIO_CASE: async (ctx, {
        scenario_id,
        case_id,
        data,
        requestId,
        sessionId,
        well_id,
    }) => _apiCall(ctx, {
        method: 'PATCH',
        url: await compileUrl('/wells/${well_id}/engineering/scenarios/${scenario_id}/cases/${case_id}', {
            well_id,
            scenario_id,
            case_id,
        }),
        data,
        requestId,
        sessionId,
    }).then(resp => resp.data.data),
    REQUEST_SCENARIO_DATA: async (ctx, {
        scenario_id,
        case_id,
        requestId,
        well_id,
    }) => _apiCall(ctx, {
        method: 'GET',
        url: await compileUrl('/wells/${well_id}/engineering/scenarios/${scenario_id}/cases/${case_id}/data', {
            well_id,
            scenario_id,
            case_id,
        }),
        requestId,
    }).then(resp => resp.data),

    CREATE_SCENARIO_CASE: async (ctx, {
        scenario_id,
        requestId,
        calculator,
        sessionId,
        well_id,
    }) => _apiCall(ctx, {
        ...defaultParams,
        method: 'POST',
        url: await compileUrl('/wells/${well_id}/engineering/scenarios/${scenario_id}/cases', {
            well_id,
            scenario_id,
        }),
        data: {
            calculator,
        },
        requestId,
        sessionId,
    }).then(resp => resp.data.data),
    DELETE_SCENARIO_CASE: async (ctx, {
        scenario_id,
        requestId,
        case_id,
        sessionId,
        well_id,
    }) => _apiCall(ctx, {
        ...defaultParams,
        method: 'DELETE',
        url: await compileUrl('/wells/${well_id}/engineering/scenarios/${scenario_id}/cases/${case_id}', {
            well_id,
            scenario_id,
            case_id,
        }),
        requestId,
        sessionId,
    }).then(resp => resp.data.data),
    REQUEST_META: async (ctx, {well_id}) => _apiCall(ctx, {
        method: 'GET',
        url: await compileUrl('/engineering/meta', {
            well_id,
        }),
    }).then(resp => resp.data.data.meta_description),
    REQUEST_BHA: async (ctx, {well_id}) => _apiCall(ctx, {
        method: 'GET',
        url: await compileUrl('wells/${well_id}/engineering/bha', {
            well_id,
        }),
    }).then(resp => groupedList(resp.data.data, 'group')),
    REQUEST_CALCULATORS: async (ctx, {well_id}) => _apiCall(ctx, {
        method: 'GET',
        url: await compileUrl('engineering/calculator', {
            well_id,
        }),
    }).then(resp => resp.data.data),
    CREATE_DRAFT: draftRequest('edit'),
    DELETE_DRAFT: draftRequest('cancel'),
    SAVE_DRAFT: draftRequest('apply'),
    REQUEST_DEBUG_DATA: async (ctx, {scenario_id, well_id}) => _apiCall(ctx, {
        url: await compileUrl('wells/${well_id}/engineering/scenarios/${scenario_id}/debug', {
            well_id,
            scenario_id,
        }),
    }).then(resp => resp.data),
    REORDER_CASES: async (ctx, {scenario_id, order, well_id}) => _apiCall(ctx, {
        method: 'PUT',
        url: await compileUrl('wells/${well_id}/engineering/scenarios/${scenario_id}/cases/reorder', {
            well_id,
            scenario_id,
        }),
        data: {order}
    }).then(resp => resp.data.data),
    UPDATE_RUNS_DATA: async (ctx, {scenario_id, payload, well_id}) => _apiCall(ctx, {
        method: 'POST',
        url: await compileUrl('wells/${well_id}/engineering/scenarios/${scenario_id}/rt_data_view', {
            well_id,
            scenario_id,
        }),
        data: payload,
    }).then(resp => resp.data),
    REQUEST_WELLS_LIST: async (ctx, {well_id}) => _apiCall(ctx, {
        method: 'GET',
        url: `engineering/wells/${well_id}`,
    }),
    GET_SCENARIO_BY_WIDGET_ID: async (ctx, {fragment_id, well_id}) => _apiCall(ctx, {
        method: 'GET',
        url: await compileUrl('/wells/${well_id}/engineering/scenarios/fragment/${fragment_id}', {
            well_id,
            fragment_id,
        }),
    }).then(resp => resp.data),
    SET_SCENARIO_ID_TO_WIDGET: async (ctx, {fragment_id, scenario_id, well_id}) => _apiCall(ctx, {
        method: 'POST',
        url: await compileUrl('/wells/${well_id}/engineering/scenarios/${scenario_id}/fragment/${fragment_id}', {
            well_id,
            fragment_id,
            scenario_id,
        }),
    }),
}

export default {
    namespaced: true,
    actions,
}

