export const DATA_REQUEST = 'DATA_REQUEST'
export const DATA_SUCCESS = 'DATA_SUCCESS'
export const ROADMAP_REQUEST = 'ROADMAP_REQUEST'
export const ROADMAP_DATA_SUCCESS = 'ROADMAP_DATA_SUCCESS'
export const DATA_ERROR = 'DATA_ERROR'
export const DATA_STATE_RESET = 'DATA_STATE_RESET'
export const DATA_SET = 'DATA_SET'
export const CUSTOM_REQUEST = 'CUSTOM_REQUEST'
export const GAUGE_DATA_STORE = 'GAUGE_DATA_STORE'
export const END_LOAD = 'END_LOAD'
