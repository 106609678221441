import Crud from '@/store/modules/crud.js'
import {apiCall, hasError} from "@/utils/api"

const URL = 'admin/users'

class Users extends Crud {
    getters = () => ({
        getUsers: state => state.data,
    });

    actions() {
        let actions = super.actions()
        actions['SEND_PASSWORD_NOTIFY'] = ({commit, dispatch}, userId) => {
            commit('CHANGE_STATUS', {name: 'processing', message: 'Sending password notify'})
            return new Promise((resolve, reject) => {
                apiCall({method: 'POST', url: `${URL}/${userId}/send_password_notify`})
                    .then(resp => {
                        commit('CHANGE_STATUS', {name: 'success', message: 'Password notify send'})
                        resolve(resp)
                    })
                    .catch(err => {
                        hasError({commit, dispatch}, err)
                        reject(err)
                    })
            })

        }
        actions['GET_PASSWORD_RESET_LINK'] = ({commit, dispatch}, userId) => {
            return new Promise((resolve, reject) => {
                apiCall({method: 'GET', url: `${URL}/${userId}/password_reset_link`})
                    .then(resp => {
                        resolve(resp.data)
                    })
                    .catch(err => {
                        hasError({commit, dispatch}, err)
                        reject(err)
                    })
            })

        }
        return actions
    }
}

const users = new Users(URL)

export default users.module()
